import { Input } from '@headlessui/react';
import StageMetadataForm from './StageMetadataForm';
import DialogFormRow from './DialogFormRow';
import { useDispatch } from 'react-redux';
import { updateScreenId, updateScreenStage } from './utils/state/screensSlice';
import DisplayPropertiesForm from './DisplayPropertiesForm';
import { Accordion } from '../shadComponents/ui/accordion';

export default function ScreenEditor({
    screen,
    onIdChange,
}: {
    screen: ScreenDefinition | null;
    onIdChange: (id: string) => void;
}) {
    const dispatch = useDispatch();

    if (!screen) {
        return null;
    }

    const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        onIdChange(value);
        dispatch(updateScreenId({ id: screen.id, newId: value }));
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        let updatedStage = { ...screen.stage, [name]: value };

        dispatch(updateScreenStage({ id: screen.id, stage: updatedStage }));
    };

    return (
        <>
            <DialogFormRow title="Screen ID" field="id">
                <Input
                    value={screen?.id}
                    id="id"
                    name="id"
                    onChange={handleIdChange}
                    className="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
            </DialogFormRow>
            <DialogFormRow title="Stage Type" field="stageType">
                <select
                    id="stageType"
                    name="type"
                    value={screen.stage.type}
                    onChange={handleSelectChange}
                    className="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                    <option value="attract">Attract</option>
                    <option value="capture">Capture</option>
                    <option value="review">Review</option>
                    <option value="share">Share</option>
                    <option value="processing">Processing</option>
                </select>
            </DialogFormRow>

            <Accordion
                type="multiple"
                className="w-full bg-[#fafafa]"
                data-state="open"
                aria-expanded="true"
            >
                <DisplayPropertiesForm screen={screen} />

                <StageMetadataForm screen={screen} />
            </Accordion>
        </>
    );
}
