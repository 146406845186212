import { createColumnHelper } from '@tanstack/react-table';
import Table from '../../Common/components/Table';
import { Link } from 'react-router-dom';

const columnHelper = createColumnHelper<any>();

interface SessionStatsTableProps {
    activationId: number;
    sessionStats: any[];
}

export default function SessionStatsTable({
    activationId,
    sessionStats,
}: SessionStatsTableProps) {
    const columns = [
        columnHelper.accessor('date', {
            id: 'date',
            header: () => <span>time</span>,
            cell: (info) => (
                <div className="row">
                    <span>{info.getValue()}</span>
                </div>
            ),
        }),
        columnHelper.accessor('total', {
            id: 'total',
            header: () => <span>total count</span>,
            cell: (info) => (
                <div className="row">
                    <span>{info.getValue()}</span>
                </div>
            ),
        }),
        columnHelper.accessor('destination', {
            id: 'destination',
            header: () => <span>recipient</span>,
            cell: (info) => (
                <div className="row">
                    <span>{info.getValue() ? info.getValue() : '-'}</span>
                </div>
            ),
        }),
        columnHelper.accessor('sent', {
            id: 'sent',
            header: () => <span></span>,
            cell: (info) => (
                <div className="row">
                    <span>{info.getValue() ? 'Sent' : 'Not Sent'}</span>
                </div>
            ),
        }),
        columnHelper.display({
            id: 'actions',
            cell: (props: { row: any }) => (
                <div className="row">
                    <Link
                        to={`/activations/${activationId}/sessions/${props.row.original.photoSessionId}`}
                        className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block center"
                    >
                        Manage
                    </Link>
                </div>
            ),
        }),
    ];

    return (
        <div>
            <div className="flex justify-end mr-8">
                <Link
                    to={`/activations/${activationId}/sessions/create`}
                    className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block center"
                >
                    Create New Session
                </Link>
            </div>
            <Table columns={columns} data={sessionStats} />
        </div>
    );
}
