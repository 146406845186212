import { useState } from 'react';
import LoadingIndicator from './LoadingIndicator';
import { useFormik } from 'formik';
import useAuthenticatedApi from '../../Authentication/components/hooks/useAuthenticatedApi';
import { Button } from '@/src/shadComponents/ui/button';
import { Progress } from '@/src/shadComponents/ui/progress';

export default function BulkUploadMedia({
    activationId,
    photoSessionId,
    onComplete,
    onCancel,
}: any) {
    const [isLoading, setIsLoading] = useState(false);
    const [uploadedCount, setUploadedCount] = useState(0);

    const { post } = useAuthenticatedApi();

    const bulkUpload = async (values: { [key: string]: string }) => {
        setUploadedCount(0);
        const files = values['photo'];

        const fileCount = files.length;
        const chunkSize = 4;

        for (let i = 0; i < fileCount; i += chunkSize) {
            const chunk = files.slice(i, i + chunkSize);

            const formData = new FormData();
            for (let i = 0; i < chunk.length; i++) {
                formData.append('photo', chunk[i]);
            }

            const response = await post(
                `/media/upload/${activationId}/bulk${
                    photoSessionId ? `/${photoSessionId}` : ''
                }`,
                formData,
            );

            if (response.status !== 200) {
                return false;
            } else {
                setUploadedCount((prev) => prev + chunk.length);
            }
        }

        return true;
    };

    const formik = useFormik({
        initialValues: {
            photo: '',
        },
        onSubmit: async (...args) => {
            setIsLoading(true);
            // @ts-ignore
            const successful = await bulkUpload(...args);

            if (successful) {
                onComplete();
            } else {
                alert('something went wrong with the upload');
            }

            setIsLoading(false);
        },
    });

    return (
        <div className="Asset-modal-container">
            <div className="modal">
                <form
                    className="Form test-form"
                    onSubmit={formik.handleSubmit}
                    encType="multpart/form-data"
                >
                    <div className="row">
                        <div className="form">
                            <div className="column">
                                <h2>Bulk Upload</h2>

                                <div className="Form-row">
                                    <label>file&nbsp;</label>

                                    <input
                                        type="file"
                                        name="photo"
                                        accept="*/*"
                                        multiple
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'photo',
                                                Array.from(
                                                    // @ts-ignore
                                                    e.currentTarget.files,
                                                ),
                                            );
                                        }}
                                    />
                                </div>
                                <div className="spacer" />
                                {isLoading ? (
                                    <div className="w-full">
                                        <div>
                                            Uploaded {uploadedCount} /{' '}
                                            {formik.values.photo.length}
                                        </div>
                                        <Progress
                                            value={
                                                (uploadedCount /
                                                    formik.values.photo
                                                        .length) *
                                                100
                                            }
                                            className="w-full"
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="row bottom-row">
                        <div className="spacer" />
                        <Button type="submit" className="Primary-button">
                            upload
                            {isLoading ? <LoadingIndicator /> : null}
                        </Button>
                        <Button onClick={onCancel} className="Secondary-button">
                            close
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
