import { useContext } from 'react';
import { ExperienceBuilderContext } from './ExperienceBuilderContext';
import { useSelector } from 'react-redux';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import ReplicateScreenDialog from './ReplicateScreenDialog';
import ReplicateScreenButton from './ReplicateScreenButton';
import ReplicateElementDialog from './ReplicateElementDialog';
import ReplicateElementButton from './ReplicateElementButton';

export default function ReplicateDialog() {
    const {
        setBulkCopyDialogOpen,
        bulkCopyTargetScreens,
        setBulkCopyTargetScreens,
        bulkCopyDialogOpen,
        bulkCopyProperties,
        selectedElementIndex,
        selectedScreenId,
        bulkCopyTargetElements,
        setBulkCopyTargetElements,
    } = useContext(ExperienceBuilderContext);

    const screens = useSelector((state: any) => state.screens.present.entities);
    const elements: [
        {
            elementId: string;
            element: any;
            elementType: UIElementType;
            screenId: string;
        },
    ] = useSelector((state: any) => {
        return Object.values(state.screens.present.entities).flatMap(
            (screen: any) =>
                screen.elements.map((element: any) => ({
                    screenId: screen.id,
                    elementId: element.id,
                    elementType: element.type,
                    element,
                })),
        );
    }) as [
        {
            elementId: string;
            element: any;
            elementType: UIElementType;
            screenId: string;
        },
    ];

    const currentScreen = screens[selectedScreenId ?? ''];

    const currentElement = currentScreen?.elements[selectedElementIndex ?? -1];

    return (
        <Dialog
            open={bulkCopyDialogOpen}
            onClose={() => setBulkCopyDialogOpen(false)}
            className="relative z-10"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                {/*{currentElement && <ReplicateElementDialog />}*/}
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            {!currentElement && (
                                <ReplicateScreenDialog
                                    screens={screens}
                                    bulkCopyTargetScreens={
                                        bulkCopyTargetScreens
                                    }
                                    setBulkCopyTargetScreens={
                                        setBulkCopyTargetScreens
                                    }
                                />
                            )}
                            {currentElement && (
                                <ReplicateElementDialog
                                    bulkCopyTargetElements={
                                        bulkCopyTargetElements
                                    }
                                    elements={elements}
                                    setBulkCopyTargetElements={
                                        setBulkCopyTargetElements
                                    }
                                />
                            )}
                        </div>

                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            {!currentElement && (
                                <ReplicateScreenButton
                                    currentScreenId={selectedScreenId ?? ''}
                                    bulkCopyTargetScreens={
                                        bulkCopyTargetScreens
                                    }
                                    screens={screens}
                                    bulkCopyProperties={bulkCopyProperties}
                                    setBulkCopyDialogOpen={
                                        setBulkCopyDialogOpen
                                    }
                                />
                            )}
                            {currentElement && (
                                <ReplicateElementButton
                                    bulkCopyTargetElements={
                                        bulkCopyTargetElements
                                    }
                                    elements={elements}
                                    bulkCopyProperties={bulkCopyProperties}
                                    setBulkCopyDialogOpen={
                                        setBulkCopyDialogOpen
                                    }
                                    currentScreenId={selectedScreenId ?? ''}
                                    currentElement={currentElement}
                                />
                            )}
                            <button
                                type="button"
                                data-autofocus
                                onClick={() => setBulkCopyDialogOpen(false)}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                                Cancel
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
