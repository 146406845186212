import { useDispatch, useSelector } from 'react-redux';
import { updateScreenMetadata } from './utils/state/screensSlice';
import DialogFormRow from './DialogFormRow';
import { Switch } from '../shadComponents/ui/switch';
import { AccordionTrigger } from '../shadComponents/ui/accordion';
import { AccordionContent } from '../shadComponents/ui/accordion';
import { AccordionItem } from '../shadComponents/ui/accordion';

interface CaptureMetadataFormProps {
    screen: ScreenDefinition;
}

export default function CaptureMetadataForm({
    screen,
}: CaptureMetadataFormProps) {
    const metadata = screen.stage.metadata as CaptureMetadata;
    const dispatch = useDispatch();

    const screenNames: [string] = useSelector((state: any) => {
        return state.screens.present.ids.map(
            (id: string) => state.screens.present.entities[id].id,
        );
    });

    const flows: [{ id: string; flow: ProcessingFlow; description: string }] =
        useSelector((state: any) => {
            return state.flows.present.ids.map((id: string) => {
                return {
                    id,
                    flow: state.flows.present.entities[id],
                    description: state.flows.present.entities[id].reduce(
                        (result: string, flow: any) => {
                            if (result === '') {
                                return flow.name;
                            }

                            return `${result}->${flow.name}`;
                        },
                        '',
                    ),
                };
            });
        });

    console.log(flows);
    if (!metadata) {
        return null;
    }

    const handleSwitchChange = (name: string, value: boolean) => {
        let updatedMetadata = { ...metadata, [name]: value };

        dispatch(
            updateScreenMetadata({ id: screen.id, metadata: updatedMetadata }),
        );
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        let updatedMetadata = { ...metadata, [name]: value };

        dispatch(
            updateScreenMetadata({ id: screen.id, metadata: updatedMetadata }),
        );
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let updatedMetadata = { ...metadata, [name]: value };

        dispatch(
            updateScreenMetadata({ id: screen.id, metadata: updatedMetadata }),
        );
    };

    const handleNumberInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const { name, value } = e.target;
        let updatedMetadata = { ...metadata, [name]: parseInt(value) };

        dispatch(
            updateScreenMetadata({ id: screen.id, metadata: updatedMetadata }),
        );
    };

    const handleFloatInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let updatedMetadata = { ...metadata, [name]: parseFloat(value) };

        dispatch(
            updateScreenMetadata({ id: screen.id, metadata: updatedMetadata }),
        );
    };

    return (
        <AccordionItem value="capture-settings">
            <AccordionTrigger className="bg-[#f4f4f4]">
                <span className="text-sm font-medium text-gray-700 mb-2 mt-2 px-6 font-bold">
                    Capture Settings
                </span>
            </AccordionTrigger>
            <AccordionContent>
                <DialogFormRow title="Mode" field="mode">
                    <select
                        className="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        name="mode"
                        value={metadata.mode}
                        onChange={handleSelectChange}
                    >
                        <option value="clicker">Clicker</option>
                        <option value="gif">GIF</option>
                        <option value="sequence">Sequence</option>
                        <option value="photo">Photo</option>
                    </select>
                </DialogFormRow>
                <DialogFormRow title="Flow" field="flowId">
                    <div className="flex flex-row">
                        <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            name="flowId"
                            value={metadata.flowId}
                            onChange={handleSelectChange}
                        >
                            {flows.map(({ id, description }) => (
                                <option key={id} value={id}>
                                    ({id}) {description}
                                </option>
                            ))}
                        </select>
                        <button
                            className="ml-2 px-4 py-1.5 bg-indigo-600 text-white rounded-md"
                            onClick={() => console.log('clicked')}
                        >
                            Add&nbsp;Flow
                        </button>
                    </div>
                </DialogFormRow>
                <DialogFormRow
                    title="Should Navigate After Capture"
                    field="shouldNavigateAfterCapture"
                >
                    <Switch
                        name="shouldNavigateAfterCapture"
                        checked={metadata.shouldNavigateAfterCapture}
                        onCheckedChange={(checked: boolean) =>
                            handleSwitchChange(
                                'shouldNavigateAfterCapture',
                                checked,
                            )
                        }
                    />
                </DialogFormRow>

                {metadata.shouldNavigateAfterCapture && (
                    <>
                        <DialogFormRow
                            title="Post Capture Destination"
                            field="postCaptureDestination"
                        >
                            <select
                                name="postCaptureDestination"
                                value={metadata.postCaptureDestination}
                                onChange={handleSelectChange}
                                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                                {screenNames.map((name) => (
                                    <option key={name} value={name}>
                                        {name}
                                    </option>
                                ))}
                            </select>
                        </DialogFormRow>
                        <DialogFormRow
                            title="Post Capture Navigation Delay"
                            field="navigateAfterCaptureDelay"
                        >
                            <input
                                type="number"
                                name="navigateAfterCaptureDelay"
                                value={metadata.navigateAfterCaptureDelay ?? 0}
                                onChange={handleNumberInputChange}
                            />
                        </DialogFormRow>
                    </>
                )}

                <DialogFormRow
                    title="Start Capture Immediately"
                    field="captureOnMount"
                >
                    <Switch
                        name="captureOnMount"
                        checked={metadata.captureOnMount ?? false}
                        onCheckedChange={(checked: boolean) =>
                            handleSwitchChange('captureOnMount', checked)
                        }
                    />
                </DialogFormRow>

                <DialogFormRow title="Prompt" field="prompt">
                    <input
                        type="text"
                        name="prompt"
                        value={metadata.prompt}
                        onChange={handleInputChange}
                    />
                </DialogFormRow>

                <DialogFormRow title="Count Down" field="countDown">
                    <input
                        type="number"
                        name="countDown"
                        value={metadata.countDown ?? ''}
                        onChange={handleNumberInputChange}
                    />
                </DialogFormRow>

                <DialogFormRow
                    title="Processing Message"
                    field="processingMessage"
                >
                    <input
                        type="text"
                        name="processingMessage"
                        value={metadata.processingMessage ?? ''}
                        onChange={handleInputChange}
                    />
                </DialogFormRow>

                <DialogFormRow title="Capture Message" field="captureMessage">
                    <input
                        type="text"
                        name="captureMessage"
                        value={metadata.captureMessage ?? ''}
                        onChange={handleInputChange}
                    />
                </DialogFormRow>

                <DialogFormRow
                    title="Dynamic Text Identifier"
                    field="dynamicTextIdentifier"
                >
                    <input
                        type="text"
                        name="dynamicTextIdentifier"
                        value={metadata.dynamicTextIdentifier ?? ''}
                        onChange={handleInputChange}
                    />
                </DialogFormRow>

                {metadata.mode === 'sequence' && (
                    <>
                        <DialogFormRow
                            title="Skip GIF Generation"
                            field="skipGifGeneration"
                            description="GIFs are generated from all photos in the sequence by default. If this is checked, the GIF will not be generated."
                        >
                            <Switch
                                name="skipGifGeneration"
                                checked={metadata.skipGifGeneration ?? false}
                                onCheckedChange={(checked: boolean) =>
                                    handleSwitchChange(
                                        'skipGifGeneration',
                                        checked,
                                    )
                                }
                            />
                        </DialogFormRow>
                        <DialogFormRow title="Photo Count" field="photoCount">
                            <input
                                type="number"
                                name="photoCount"
                                value={metadata.photoCount ?? 0}
                                onChange={handleNumberInputChange}
                            />
                        </DialogFormRow>
                    </>
                )}

                {metadata.mode === 'gif' && (
                    <>
                        <DialogFormRow title="GIF Duration" field="gifDuration">
                            <input
                                type="number"
                                name="gifDuration"
                                value={metadata.gifDuration ?? ''}
                                onChange={handleFloatInputChange}
                            />
                        </DialogFormRow>
                        <DialogFormRow
                            title="GIF Capture Duration"
                            field="gifCaptureDuration"
                            description="The duration the shutter release is engaged for each session. The number of photos captured is dependent on the camera and other equipment being used."
                        >
                            <div className="flex flex-row">
                                <input
                                    type="number"
                                    name="gifCaptureDuration"
                                    value={metadata.gifCaptureDuration ?? 1.9}
                                    onChange={handleFloatInputChange}
                                    className="w-14 mx-0 px-0 text-center"
                                />
                                <p className="text-sm text-gray-500 pr-6 pl-1 pt-4">
                                    seconds
                                </p>
                                <div className="flex-1"></div>
                            </div>
                        </DialogFormRow>
                    </>
                )}

                {metadata.mode === 'clicker' && (
                    <>
                        <DialogFormRow
                            title="Post Capture Action"
                            field="postCaptureAction"
                        >
                            <select
                                name="postCaptureAction"
                                value={metadata.postCaptureAction ?? ''}
                                onChange={handleSelectChange}
                            >
                                <option value=""></option>
                                <option value="shareAndReset">
                                    Share and Reset
                                </option>
                            </select>
                        </DialogFormRow>
                        <DialogFormRow title="Value Key" field="valueKey">
                            <input
                                type="text"
                                name="valueKey"
                                value={metadata.valueKey ?? ''}
                                onChange={handleInputChange}
                            />
                        </DialogFormRow>
                        <DialogFormRow title="Timeout" field="timeout">
                            <input
                                type="number"
                                name="timeout"
                                value={metadata.timeout ?? ''}
                                onChange={handleFloatInputChange}
                            />
                        </DialogFormRow>
                    </>
                )}
            </AccordionContent>
        </AccordionItem>
    );
}
