import { useEffect, useState } from 'react';
import useAuthenticatedApi from '../Authentication/components/hooks/useAuthenticatedApi';

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
}

export default function ServerHealthTable() {
    const [diskUsage, setDiskUsage] = useState<string>();
    const [hostname, setHostname] = useState<string>();
    const authenticatedApi = useAuthenticatedApi();

    useEffect(() => {
        let isMounted = true;
        let getData = async () => {
            let results = await authenticatedApi.get(
                '/telemetry/server-resource-usage',
            );

            if (isMounted) {
                setDiskUsage(results.data.diskUsage);
                setHostname(results.data.hostname);
            }
        };
        getData();

        let interval = setInterval(getData, 10000);

        return () => {
            isMounted = false;
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
            <dl className="mx-auto grid max-w-9xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 lg:px-2 xl:px-0">
                <div
                    key={'hostname'}
                    className={classNames(
                        'sm:border-l flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8',
                    )}
                >
                    <dt className="text-sm font-medium leading-6 text-gray-500">
                        Hostname
                    </dt>
                    <dd
                        className={classNames(
                            'text-gray-700',
                            'text-xs font-medium',
                        )}
                    ></dd>
                    <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900 whitespace-nowrap">
                        {hostname}
                    </dd>
                </div>
                <div
                    key={'diskUsage'}
                    className={classNames(
                        'sm:border-l flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8',
                    )}
                >
                    <dt className="text-sm font-medium leading-6 text-gray-500">
                        Disk Usage
                    </dt>
                    <dd
                        className={classNames(
                            'text-gray-700',
                            'text-xs font-medium',
                        )}
                    ></dd>
                    <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                        {diskUsage}
                    </dd>
                </div>
            </dl>
        </div>
    );
}
