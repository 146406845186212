import { Input } from '@headlessui/react';
import DialogFormRow from './DialogFormRow';
import { useDispatch } from 'react-redux';
import {
    updateScreenUIElement,
    changeUIElementType,
} from './utils/state/screensSlice';
import UIElementPropertiesForm from './UIElementPropertiesForm';

export default function UIElementEditor({
    screen,
    element,
    onIdChange,
}: {
    screen: ScreenDefinition | null;
    element: UIElement | null;
    onIdChange: (id: string) => void;
}) {
    const dispatch = useDispatch();

    if (!element) {
        return null;
    }

    if (!screen) {
        return null;
    }

    console.log('2 element', element);
    console.log('2 screen', screen);

    const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        onIdChange(value);
        dispatch(updateScreenUIElement({ id: element.id, newId: value }));
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = e.target;

        dispatch(
            changeUIElementType({
                screenId: screen.id,
                elementId: element.id,
                type: value as UIElementType,
            }),
        );
    };

    return (
        <>
            <DialogFormRow title="Element Id" field="id">
                <Input
                    value={element?.id}
                    id="id"
                    name="id"
                    onChange={handleIdChange}
                    className="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
            </DialogFormRow>
            <DialogFormRow title="Element Type" field="elementType">
                <select
                    id="type"
                    name="type"
                    value={element?.type}
                    onChange={handleSelectChange}
                    className="block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                    <option value="text">Text</option>
                    <option value="dynamicText">Dynamic Text</option>
                    <option value="button">Button</option>
                    <option value="image">Image</option>
                    <option value="input">Input</option>
                    <option value="outputPreview">Output Preview</option>
                    <option value="currentSessionQR">Current Session QR</option>
                </select>
            </DialogFormRow>

            <UIElementPropertiesForm element={element} screen={screen} />
        </>
    );
}
