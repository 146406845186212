import { useDispatch } from 'react-redux';
import { Input } from '../shadComponents/ui/input';
import { ColorPicker } from './ColorPicker';
import DialogFormRow from './DialogFormRow';
import { updateScreenDisplayProperties } from './utils/state/screensSlice';
import {
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '../shadComponents/ui/accordion';
import { useContext } from 'react';
import { CheckSquare, Square } from 'lucide-react';
import { ExperienceBuilderContext } from './ExperienceBuilderContext';

interface DisplayPropertiesFormProps {
    screen: ScreenDefinition;
}

export default function DisplayPropertiesForm({
    screen,
}: DisplayPropertiesFormProps) {
    const dispatch = useDispatch();

    const displayProperties = screen.displayProperties;
    const handleValueChange = (name: string, value: string) => {
        let updatedDisplayProperties = { ...displayProperties, [name]: value };
        dispatch(
            updateScreenDisplayProperties({
                id: screen.id,
                displayProperties: updatedDisplayProperties,
            }),
        );
    };

    const { bulkCopyMode, bulkCopyProperties, setBulkCopyProperties } =
        useContext(ExperienceBuilderContext);

    const bulkCopyCheckbox = (property: string) => (
        <>
            <div className="flex-1" />
            <div
                className="mt-2"
                onClick={() => {
                    if (bulkCopyProperties.includes(property)) {
                        setBulkCopyProperties(
                            bulkCopyProperties.filter(
                                (bulkProperty) => bulkProperty !== property,
                            ),
                        );
                    } else {
                        setBulkCopyProperties([
                            ...bulkCopyProperties,
                            property,
                        ]);
                    }
                }}
            >
                {bulkCopyProperties.includes(property) ? (
                    <CheckSquare />
                ) : (
                    <Square />
                )}
            </div>
        </>
    );

    return (
        <AccordionItem value="display-properties">
            <AccordionTrigger className="bg-[#f4f4f4]">
                <div className="flex items-center gap-2">
                    <span className="text-sm font-medium text-gray-700 mb-2 mt-2 px-6 font-bold">
                        Display Properties
                    </span>
                </div>
            </AccordionTrigger>
            <AccordionContent>
                <DialogFormRow title="Background Color" field="backgroundColor">
                    <div className="flex items-center gap-2">
                        <ColorPicker
                            name="backgroundColor"
                            value={displayProperties.backgroundColor}
                            onChange={(value) =>
                                handleValueChange('backgroundColor', value)
                            }
                        />
                        <span className="text-sm text-black-500">
                            {displayProperties.backgroundColor}
                        </span>

                        {bulkCopyMode && bulkCopyCheckbox('backgroundColor')}
                    </div>
                </DialogFormRow>
                <DialogFormRow title="Foreground Color" field="foregroundColor">
                    <div className="flex items-center gap-2">
                        <ColorPicker
                            name="foregroundColor"
                            value={displayProperties.foregroundColor}
                            onChange={(value) =>
                                handleValueChange('foregroundColor', value)
                            }
                        />
                        <span className="text-sm text-black-500">
                            {displayProperties.foregroundColor}
                        </span>

                        {bulkCopyMode && bulkCopyCheckbox('foregroundColor')}
                    </div>
                </DialogFormRow>
                <DialogFormRow title="Button Color" field="buttonColor">
                    <div className="flex items-center gap-2">
                        <ColorPicker
                            name="buttonColor"
                            value={displayProperties.buttonColor}
                            onChange={(value) =>
                                handleValueChange('buttonColor', value)
                            }
                        />
                        <span className="text-sm text-black-500">
                            {displayProperties.buttonColor}
                        </span>

                        {bulkCopyMode && bulkCopyCheckbox('buttonColor')}
                    </div>
                </DialogFormRow>
                <DialogFormRow
                    title="Button Text Color"
                    field="buttonTextColor"
                >
                    <div className="flex items-center gap-2">
                        <ColorPicker
                            name="buttonTextColor"
                            value={displayProperties.buttonTextColor}
                            onChange={(value) =>
                                handleValueChange('buttonTextColor', value)
                            }
                        />
                        <span className="text-sm text-black-500">
                            {displayProperties.buttonTextColor}
                        </span>

                        {bulkCopyMode && bulkCopyCheckbox('buttonTextColor')}
                    </div>
                </DialogFormRow>
                <DialogFormRow
                    title="Background Media URL"
                    field="backgroundMediaUrl"
                >
                    <div className="flex items-center gap-2">
                        <Input
                            name="backgroundMediaUrl"
                            value={displayProperties.backgroundMediaUrl}
                            onChange={(e) =>
                                handleValueChange(
                                    'backgroundMediaUrl',
                                    e.target.value,
                                )
                            }
                        />

                        {bulkCopyMode && bulkCopyCheckbox('backgroundMediaUrl')}
                    </div>
                </DialogFormRow>
                <DialogFormRow
                    title="Horizontal Padding"
                    field="horizontalPadding"
                >
                    <div className="flex items-center gap-2">
                        <Input
                            name="horizontalPadding"
                            value={displayProperties.horizontalPadding ?? 0}
                            onChange={(e) =>
                                handleValueChange(
                                    'horizontalPadding',
                                    e.target.value,
                                )
                            }
                        />
                        <span className="text-sm text-black-500">px</span>
                        {bulkCopyMode && bulkCopyCheckbox('horizontalPadding')}
                    </div>
                </DialogFormRow>
                <DialogFormRow title="Vertical Padding" field="verticalPadding">
                    <div className="flex items-center gap-2">
                        <Input
                            name="verticalPadding"
                            value={displayProperties.verticalPadding ?? 0}
                            onChange={(e) =>
                                handleValueChange(
                                    'verticalPadding',
                                    e.target.value,
                                )
                            }
                        />
                        <span className="text-sm text-black-500">px</span>

                        {bulkCopyMode && bulkCopyCheckbox('verticalPadding')}
                    </div>
                </DialogFormRow>
            </AccordionContent>
        </AccordionItem>
    );
}
