import { useState, useEffect } from 'react';
import useExternalSurvey from './hooks/useExternalSurvey';
import Loading from '@/src/Common/components/Loading';

interface ExternalSurveyProps {
    galleryInfo: GalleryObject | null;
    destinationIdentifier: string;
    onLoad: () => void;
}

export default function ExternalSurvey({
    galleryInfo,
    destinationIdentifier,
    onLoad = () => {},
}: ExternalSurveyProps) {
    const {
        hasSurveyBeenCompleted,
        checkSurveyCompletion,
        surveyToken,
        hasTokenBeenClaimed,
    } = useExternalSurvey(galleryInfo);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        checkSurveyCompletion(destinationIdentifier);
        setIsLoading(false);
        onLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [destinationIdentifier]);

    useEffect(() => {
        if (hasTokenBeenClaimed) {
            checkSurveyCompletion(destinationIdentifier);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasTokenBeenClaimed]);

    if (isLoading) {
        return <Loading isLoading={isLoading} />;
    }

    if (hasSurveyBeenCompleted) {
        return null;
    }

    if (!galleryInfo?.surveyUrl) {
        return null;
    }

    const surveyUrl = `${galleryInfo?.surveyUrl}?token=${surveyToken}&activationId=${galleryInfo?.activationId}&destinationIdentifier=${destinationIdentifier}`;

    return (
        <div className="h-full w-full z-[1000] fixed top-0 left-0 cover-bg">
            <iframe className="h-full w-full" src={surveyUrl} title="Survey" />
        </div>
    );
}
