import { useDispatch } from 'react-redux';
import { updateScreenDisplayProperties } from './utils/state/screensSlice';

export default function ReplicateScreenButton({
    bulkCopyTargetScreens,
    screens,
    bulkCopyProperties,
    setBulkCopyDialogOpen,
    currentScreenId,
}: {
    currentScreenId: string;
    bulkCopyTargetScreens: string[];
    screens: Record<string, ScreenDefinition>;
    bulkCopyProperties: Record<string, any>;
    setBulkCopyDialogOpen: (open: boolean) => void;
}) {
    const dispatch = useDispatch();
    return (
        <button
            type="button"
            onClick={() => {
                bulkCopyTargetScreens.forEach((screenId: string) => {
                    let screen = screens[screenId];
                    let currentScreen = screens[currentScreenId];
                    let copiedDisplayProperties: Record<string, any> = {};
                    bulkCopyProperties.forEach((property: string) => {
                        copiedDisplayProperties[property] =
                            // @ts-ignore
                            currentScreen.displayProperties[property];
                    });

                    dispatch(
                        updateScreenDisplayProperties({
                            id: screen.id,
                            displayProperties: {
                                ...screen.displayProperties,
                                ...copiedDisplayProperties,
                            },
                        }),
                    );
                });
                setBulkCopyDialogOpen(false);
            }}
            className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
        >
            Replicate
        </button>
    );
}
