import {
    GridIcon,
    PresentationIcon,
    PlusSquareIcon,
    FileStackIcon,
} from 'lucide-react';
import { ExperienceBuilderContext } from './ExperienceBuilderContext';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { createScreen } from './utils/state/screensSlice';

export default function DesignerToolbar({
    showGridLines,
    setShowGridLines,
    showPresentation,
    setShowPresentation,
}: {
    showGridLines: boolean;
    setShowGridLines: (show: boolean) => void;
    showPresentation: boolean;
    setShowPresentation: (show: boolean) => void;
}) {
    const { setSelectedScreenId, setBulkCopyMode, bulkCopyMode } = useContext(
        ExperienceBuilderContext,
    );
    const dispatch = useDispatch();

    return (
        <div className="fixed bottom-[72px] order border-gray-300 border-solid left-2 bg-white rounded-lg flex-col p-2 gap-3 grid">
            <button
                className={`flex items-center gap-2`}
                onClick={() => {
                    const newScreen = {
                        id: `screen-${Math.random()
                            .toString(36)
                            .substring(2, 8)}`,
                        elements: [],
                    };
                    dispatch(createScreen(newScreen));
                    setSelectedScreenId(newScreen.id);
                }}
                title="Add Screen"
            >
                <PlusSquareIcon />
            </button>
            <button
                className={`flex items-center gap-2 ${
                    bulkCopyMode ? 'text-blue-500' : ''
                }`}
                onClick={() => setBulkCopyMode(!bulkCopyMode)}
                title="Replicate Tool - Copy settings to other screens and elements."
            >
                <FileStackIcon />
            </button>
            <div className="border-t border-gray-300"></div>
            <button
                className={` flex items-center gap-2 ${
                    showGridLines ? 'text-blue-500' : ''
                }`}
                onClick={() => setShowGridLines(!showGridLines)}
                title="Show Grid Lines"
            >
                <GridIcon />
            </button>
            <button
                className={`flex items-center gap-2 ${
                    showPresentation ? 'text-blue-500' : ''
                }`}
                onClick={() => setShowPresentation(!showPresentation)}
                title="Presentation Preview"
            >
                <PresentationIcon />
            </button>
        </div>
    );
}
