import ExperienceGraph from './ExperienceGraph';

interface ExperienceEditorProps {
    screens: ScreenDefinition[];
    onScreenSelected: (screenId: string) => void;
    onDefinitionChange: (definition: Definition) => void;
}

export default function ExperienceEditor({
    screens,
    onScreenSelected,
    onDefinitionChange,
}: ExperienceEditorProps) {
    if (!screens || screens.length === 0) {
        return (
            <ScreenCard>
                <i className="bi bi-plus text-2xl cursor-pointer"></i>
                <div>Create Screen</div>
            </ScreenCard>
        );
    }

    console.log('Screen count', screens.length);

    return (
        <ExperienceGraph
            screens={screens}
            onScreenSelected={onScreenSelected}
        />
    );
}

export function ScreenCard({ children }: { children: React.ReactNode }) {
    return (
        <div className="overflow-hidden bg-[#EEEFEF] shadow-md sm:rounded-lg max-w-[280px]">
            <div className="flex flex-col items-center justify-center px-4 py-5 sm:p-6  h-[400px]">
                {children}
            </div>
        </div>
    );
}
