import { Formik, Form, Field, FieldArray } from 'formik';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Editor from 'react-simple-code-editor';
import useGallery from './hooks/useGallery';
import Prism from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import useTemplates, {
    EmailTemplate,
} from '../../EmailTemplates/components/hooks/useTemplates';
import CrowdGallery from './CrowdGallery';
import useSurveyQuestions from './hooks/useSurveyQuestions';
import { Button } from '@/src/shadComponents/ui/button';
import FeatureFlag from '@/src/Features/components/FeatureFlag';
import { FeatureFlags } from '@/src/Features/lib/constants';
import usePicTime from '@/src/Orgs/components/hooks/usePicTime';

interface SurveyQuestion {
    question: string;
    answerTypeId: number;
    id?: number;
    isDeleted?: boolean;
}

interface GalleryMediaFilter {
    enable: boolean;
    original: boolean;
    videos: boolean;
    photos: boolean;
    prints: boolean;
    edited: boolean;
}

interface GalleryObject {
    name: string;
    isPrivate: boolean;
    password: string;
    slug: string;
    id?: number;
    textSettingsId?: number;
    emailSettingsId?: number;
    activationId: number;
    emailBody: string;
    emailEnabled: boolean;
    fromAddress: string;
    fromName: string;
    subject: string;
    textBody: string;
    textEnabled: boolean;
    foregroundColor: string;
    backgroundColor: string;
    qrLogo: string;
    bottomLogo: string;
    logo: string;
    font: string;
    ctaImage: string;
    ctaText: string;
    ctaLink: string;
    headerBackgroundColor: string;
    headerForegroundColor: string;
    sendLinkToPhoto: boolean;
    showGalleryLink: boolean;
    surveyRequired: boolean;
    ageVerification: boolean;
    verifiedAge: number | null;
    surveyQuestions: [SurveyQuestion];
    galleryMediaFilter: GalleryMediaFilter;
    disclaimer: string;
    disclaimerRequired: boolean;
    disclaimerEnabled: boolean;
    disclaimerOptOut: boolean;
    metaPixelId: string;
    syncWithPictime?: boolean;
    createShareDescriptionText: string;
    createShareDescriptionTextSize: number;
    createShareDescriptionTextFont: string;
    surveyUrl: string | null;
}

export default function EditGallery() {
    const navigate = useNavigate();
    const [hasPictimeSettings, setHasPictimeSettings] =
        useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(true);
    const [gallery, setGallery] = useState<GalleryObject | null>(null);
    const { galleryId: id } = useParams();
    const { getGallery, saveGallery } = useGallery();
    const { getSurveyQuestions } = useSurveyQuestions();
    const [templates, setTemplates] = useState<EmailTemplate[]>([]);
    const [selectedTemplate, setSelectedTemplate] =
        useState<EmailTemplate | null>(null);
    const { getTemplates } = useTemplates();
    const galleryId = id ? +id : 0;
    const { isLinkedToPictime } = usePicTime();

    useEffect(() => {
        let isMounted = true;
        const getAccounts = async () => {
            try {
                const gallery = await getGallery(galleryId);
                const surveyQuestions = await getSurveyQuestions(
                    gallery.activationId,
                );
                const templates = await getTemplates();
                const hasPictimeSettings = await isLinkedToPictime();

                if (hasPictimeSettings) {
                }

                gallery.surveyQuestions = surveyQuestions;
                setGallery(gallery);
                setTemplates(templates);
                setHasPictimeSettings(hasPictimeSettings);
            } catch (error) {
                console.error(error);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        getAccounts();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <div>loading</div>;
    }

    if (!gallery) {
        return <div>gallery loading error, does it exist?</div>;
    }

    return (
        <div>
            <div className="Row-container">
                <h1>edit gallery</h1>
                &nbsp;
                <CrowdGallery galleryId={galleryId} />
                <Link to="ftp">[ edit ftp ]</Link>
            </div>
            <Formik
                initialValues={gallery}
                onSubmit={async (values, actions) => {
                    const wasSuccessful = await saveGallery(galleryId, values);
                    actions.setSubmitting(false);

                    if (wasSuccessful) {
                        navigate(-1);
                    }
                }}
            >
                {(props) => (
                    <Form
                        className="Form"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    >
                        <div>
                            <label htmlFor="name">name</label>
                            <Field
                                id="name"
                                name="name"
                                placeholder="Gallery Name"
                            />
                        </div>
                        <br />
                        <div>
                            <label htmlFor="slug">slug</label>
                            <Field id="slug" name="slug" placeholder="slug" />
                        </div>
                        <br />
                        <div>
                            <label htmlFor="isPrivate">requires password</label>
                            <div className="left-justify">
                                <Field
                                    id="isPrivate"
                                    name="isPrivate"
                                    type="checkbox"
                                />
                            </div>
                        </div>
                        <br />
                        {props.values.isPrivate ? (
                            <>
                                <div>
                                    <label htmlFor="password">password</label>
                                    <Field id="password" name="password" />
                                </div>
                                <br />
                            </>
                        ) : null}

                        <h2>gallery</h2>
                        <div>
                            <label htmlFor="backgroundColor">background</label>
                            <Field
                                id="backgroundColor"
                                name="backgroundColor"
                                placeholder="#FFFFFF"
                            />
                        </div>
                        <div>
                            <label htmlFor="foregroundColor">foreground</label>
                            <Field
                                id="foregroundColor"
                                name="foregroundColor"
                                placeholder="#000000"
                            />
                        </div>
                        <div>
                            <label htmlFor="headerBackgroundColor">
                                header background
                            </label>
                            <Field
                                id="headerBackgroundColor"
                                name="headerBackgroundColor"
                                placeholder="#000000"
                            />
                        </div>
                        <div>
                            <label htmlFor="headerForegroundColor">
                                header foreground
                            </label>
                            <Field
                                id="headerForegroundColor"
                                name="headerForegroundColor"
                                placeholder="#FFFFFF"
                            />
                        </div>
                        <div>
                            <label htmlFor="logo">logo url</label>
                            <Field
                                id="logo"
                                name="logo"
                                placeholder="url to logo"
                            />
                        </div>
                        <div>
                            <label htmlFor="ctaImage">CTA image url</label>
                            <Field
                                id="ctaImage"
                                name="ctaImage"
                                placeholder="url to cta image"
                            />
                        </div>
                        <div>
                            <label htmlFor="ctaLink">CTA link url</label>
                            <Field
                                id="ctaLink"
                                name="ctaLink"
                                placeholder="url to cta link"
                            />
                        </div>
                        <div>
                            <label htmlFor="ctaText">CTA text</label>
                            <Field
                                id="ctaText"
                                name="ctaText"
                                placeholder="cta text"
                            />
                        </div>
                        {hasPictimeSettings ? (
                            <div>
                                <label htmlFor="syncWithPictime">
                                    sync with pic-time
                                </label>
                                <div className="left-justify">
                                    <Field
                                        id="syncWithPictime"
                                        name="syncWithPictime"
                                        type="checkbox"
                                    />
                                </div>
                            </div>
                        ) : null}

                        <FeatureFlag
                            flag={FeatureFlags.CUSTOMIZABLE_SHARING_STATION_V1}
                        >
                            <>
                                <div>
                                    <label htmlFor="sharingStationArea1ImageUri">
                                        Sharing Left Image
                                    </label>
                                    <Field
                                        id="sharingStationArea1ImageUri"
                                        name="sharingStationArea1ImageUri"
                                        placeholder="url to image on left side of sharing experience"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="sharingStationArea2ImageUri">
                                        Sharing Right Image
                                    </label>
                                    <Field
                                        id="sharingStationArea2ImageUri"
                                        name="sharingStationArea2ImageUri"
                                        placeholder="url to image on right side of sharing experience"
                                    />
                                </div>
                            </>
                        </FeatureFlag>
                        {/*
                        <div>
                            <label htmlFor="qrLogo">qr logo</label>
                            <Field id="qrLogo" name="qrLogo" placeholder="" />
                        </div>
                        <div>
                            <label htmlFor="bottomLogo">footer logo</label>
                            <Field
                                id="bottomLogo"
                                name="bottomLogo"
                                placeholder=""
                            />
                        </div>*/}
                        <h2>sharing experience</h2>
                        <div>
                            <label htmlFor="sendLinkToPhoto">
                                link to photo
                            </label>
                            <div className="left-justify">
                                <Field
                                    id="sendLinkToPhoto"
                                    name="sendLinkToPhoto"
                                    type="checkbox"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="showGalleryLink">
                                show gallery link
                            </label>
                            <div className="left-justify">
                                <Field
                                    id="showGalleryLink"
                                    name="showGalleryLink"
                                    type="checkbox"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="textEnabled">text enabled</label>
                            <div className="left-justify">
                                <Field
                                    id="textEnabled"
                                    name="textEnabled"
                                    type="checkbox"
                                />
                            </div>
                        </div>
                        <br />
                        {props.values.textEnabled ? (
                            <>
                                <div>
                                    <label htmlFor="textBody">text body</label>
                                    <Field
                                        id="textBody"
                                        name="textBody"
                                        component="textarea"
                                        rows="4"
                                    />
                                </div>
                                <br />
                            </>
                        ) : null}
                        <br />
                        <div>
                            <label htmlFor="emailEnabled">email enabled</label>
                            <div className="left-justify">
                                <Field
                                    id="emailEnabled"
                                    name="emailEnabled"
                                    type="checkbox"
                                />
                            </div>
                        </div>
                        <br />
                        {props.values.emailEnabled ? (
                            <>
                                <div>
                                    <label htmlFor="emailAddress">
                                        email address
                                    </label>
                                    <Field
                                        id="emailAddress"
                                        name="fromAddress"
                                    />
                                </div>

                                <br />

                                <div>
                                    <label htmlFor="fromName">from name</label>
                                    <Field id="fromName" name="fromName" />
                                </div>

                                <br />

                                <div>
                                    <label htmlFor="emailSubject">
                                        email subject
                                    </label>
                                    <Field id="subject" name="subject" />
                                </div>
                                <br />

                                <div className="Editor-row">
                                    <div className="Title-row">
                                        <span className="label">
                                            email body
                                        </span>
                                        <div className="spacer" />
                                        <select
                                            onChange={(e) =>
                                                setSelectedTemplate(
                                                    templates.find(
                                                        (template) =>
                                                            template.id ===
                                                            +e.currentTarget
                                                                .value,
                                                    ) ?? null,
                                                )
                                            }
                                        >
                                            <option>select template</option>
                                            {templates.map((template) => {
                                                return (
                                                    <option value={template.id}>
                                                        {template.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <span
                                            className="Link-button"
                                            onClick={() => {
                                                if (selectedTemplate) {
                                                    props.setFieldValue(
                                                        'emailBody',
                                                        selectedTemplate?.body,
                                                    );
                                                }
                                            }}
                                        >
                                            apply template
                                        </span>
                                    </div>
                                    <div className="Editor-container">
                                        <Editor
                                            value={props.values.emailBody}
                                            onValueChange={(code) =>
                                                props.setFieldValue(
                                                    'emailBody',
                                                    code,
                                                )
                                            }
                                            highlight={(code) =>
                                                Prism.highlight(
                                                    code,
                                                    Prism.languages.html,
                                                    'html',
                                                )
                                            }
                                            padding={10}
                                            style={{
                                                fontFamily:
                                                    '"Fira code", "Fira Mono", monospace',
                                                fontSize: 14,
                                            }}
                                        />
                                    </div>
                                </div>
                                <br />
                            </>
                        ) : null}

                        <h2>data collection</h2>
                        <div>
                            <label htmlFor="metaPixelId">meta pixel id</label>
                            <Field id="metaPixelId" name="metaPixelId" />
                        </div>
                        <div>
                            <label htmlFor="disclaimerEnabled">
                                disclaimer enabled for share
                            </label>
                            <div className="left-justify">
                                <Field
                                    id="disclaimerEnabled"
                                    name="disclaimerEnabled"
                                    type="checkbox"
                                />
                            </div>
                        </div>
                        {props.values.disclaimerEnabled ? (
                            <>
                                <div>
                                    <label htmlFor="disclaimerRequired">
                                        disclaimer required for share
                                    </label>
                                    <div className="left-justify">
                                        <Field
                                            id="disclaimerRequired"
                                            name="disclaimerRequired"
                                            type="checkbox"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="disclaimerOptOut">
                                        Is Opt Out
                                    </label>
                                    <div className="left-justify">
                                        <Field
                                            id="disclaimerOptOut"
                                            name="disclaimerOptOut"
                                            type="checkbox"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="disclaimer">
                                        disclaimer
                                    </label>
                                    <Field id="disclaimer" name="disclaimer" />
                                </div>
                            </>
                        ) : null}

                        <div>
                            <label htmlFor="createShareDescriptionText">
                                Share Description
                            </label>
                            <Field
                                id="createShareDescriptionText"
                                name="createShareDescriptionText"
                            />
                        </div>

                        <div>
                            <label htmlFor="createShareDescriptionTextSize">
                                Share Description Font Size
                            </label>
                            <Field
                                id="createShareDescriptionTextSize"
                                name="createShareDescriptionTextSize"
                            />
                        </div>

                        <div>
                            <label htmlFor="createShareDescriptionTextFont">
                                Share Description Font
                            </label>
                            <Field
                                id="createShareDescriptionTextSizeFont"
                                name="createShareDescriptionTextSizFont"
                            />
                        </div>

                        <div>
                            <label htmlFor="surveyRequired">
                                survey required for share
                            </label>
                            <div className="left-justify">
                                <Field
                                    id="surveyRequired"
                                    name="surveyRequired"
                                    type="checkbox"
                                />
                            </div>
                        </div>
                        <FeatureFlag flag={FeatureFlags.SURVEY_URL_V1}>
                            <div>
                                <label htmlFor="surveyUrl">Survey URL</label>
                                <Field
                                    id="surveyUrl"
                                    name="surveyUrl"
                                    type="text"
                                    placeholder="Enter survey URL"
                                />
                            </div>
                        </FeatureFlag>
                        {props.values.surveyRequired ? (
                            <div>
                                <FieldArray
                                    name="surveyQuestions"
                                    render={(arrayHelpers) => (
                                        <>
                                            <ol className="Form-input-list">
                                                {props.values.surveyQuestions &&
                                                props.values.surveyQuestions
                                                    .length > 0
                                                    ? props.values.surveyQuestions.map(
                                                          (question, index) => {
                                                              return question.isDeleted ? null : (
                                                                  <li
                                                                      key={`${props.values.surveyQuestions.length}-${index}`}
                                                                  >
                                                                      <div className="entry">
                                                                          <Field
                                                                              id={`surveyQuestions.${index}.question`}
                                                                              name={`surveyQuestions.${index}.question`}
                                                                          />
                                                                          <Field
                                                                              id={`surveyQuestions.${index}.answerTypeId`}
                                                                              name={`surveyQuestions.${index}.answerTypeId`}
                                                                              as="select"
                                                                          >
                                                                              <option
                                                                                  value={
                                                                                      2
                                                                                  }
                                                                              >
                                                                                  text
                                                                              </option>
                                                                              <option
                                                                                  value={
                                                                                      1
                                                                                  }
                                                                              >
                                                                                  yes/no
                                                                              </option>
                                                                          </Field>
                                                                          <Button
                                                                              type="button"
                                                                              className="Primary-button"
                                                                              onClick={() =>
                                                                                  arrayHelpers.push(
                                                                                      {
                                                                                          question:
                                                                                              '',
                                                                                          answerTypeId: 1,
                                                                                      },
                                                                                  )
                                                                              }
                                                                          >
                                                                              +
                                                                          </Button>
                                                                          <Button
                                                                              type="button"
                                                                              className="Primary-button"
                                                                              onClick={() =>
                                                                                  arrayHelpers.replace(
                                                                                      index,
                                                                                      {
                                                                                          ...props
                                                                                              .values
                                                                                              .surveyQuestions[
                                                                                              index
                                                                                          ],
                                                                                          isDeleted:
                                                                                              true,
                                                                                      },
                                                                                  )
                                                                              }
                                                                          >
                                                                              -
                                                                          </Button>
                                                                      </div>
                                                                  </li>
                                                              );
                                                          },
                                                      )
                                                    : null}
                                            </ol>
                                            {props.values?.surveyQuestions
                                                .length < 1 ? (
                                                <Button
                                                    className="Primary-button"
                                                    type="button"
                                                    onClick={() =>
                                                        arrayHelpers.push({
                                                            question: '',
                                                            answerTypeId: 1,
                                                        })
                                                    }
                                                >
                                                    add question
                                                </Button>
                                            ) : null}
                                        </>
                                    )}
                                />
                            </div>
                        ) : null}
                        <div>
                            <label htmlFor="ageVerification">
                                age verification required for share
                            </label>
                            <div className="left-justify">
                                <Field
                                    id="ageVerification"
                                    name="ageVerification"
                                    type="checkbox"
                                />
                            </div>
                        </div>
                        {props.values.ageVerification ? (
                            <>
                                <div>
                                    <label htmlFor="verifiedAge">Age</label>
                                    <Field
                                        id="verifiedAge"
                                        name="verifiedAge"
                                    />
                                </div>
                                <br />
                            </>
                        ) : null}
                        <br />
                        <h2>media display settings</h2>
                        <div>
                            <label htmlFor="galleryMediaFilter.enable">
                                media type filters
                            </label>
                            <div className="left-justify">
                                <Field
                                    id="galleryMediaFilter.enable"
                                    name="galleryMediaFilter.enable"
                                    type="checkbox"
                                />
                            </div>
                        </div>
                        {props.values.galleryMediaFilter.enable ? (
                            <>
                                <div>
                                    <label htmlFor="galleryMediaFilter.original">
                                        show originals
                                    </label>
                                    <div className="left-justify">
                                        <Field
                                            id="galleryMediaFilter.original"
                                            name="galleryMediaFilter.original"
                                            type="checkbox"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="galleryMediaFilter.photos">
                                        show photos
                                    </label>
                                    <div className="left-justify">
                                        <Field
                                            id="galleryMediaFilter.photos"
                                            name="galleryMediaFilter.photos"
                                            type="checkbox"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="galleryMediaFilter.videos">
                                        show videos
                                    </label>
                                    <div className="left-justify">
                                        <Field
                                            id="galleryMediaFilter.videos"
                                            name="galleryMediaFilter.videos"
                                            type="checkbox"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="galleryMediaFilter.edited">
                                        show edited
                                    </label>
                                    <div className="left-justify">
                                        <Field
                                            id="galleryMediaFilter.edited"
                                            name="galleryMediaFilter.edited"
                                            type="checkbox"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="galleryMediaFilter.prints">
                                        show prints
                                    </label>
                                    <div className="left-justify">
                                        <Field
                                            id="galleryMediaFilter.prints"
                                            name="galleryMediaFilter.prints"
                                            type="checkbox"
                                        />
                                    </div>
                                </div>
                            </>
                        ) : null}

                        <div className="Layout-row">
                            <Button type="submit" className="Primary-button">
                                save gallery
                            </Button>
                            <Button
                                type="button"
                                onClick={() => navigate(-1)}
                                className="Secondary-button"
                            >
                                <span>cancel</span>
                            </Button>
                        </div>

                        <br />
                    </Form>
                )}
            </Formik>
        </div>
    );
}
