import { useDispatch } from 'react-redux';
import { copyUIElementMetadataToUIElement } from './utils/state/screensSlice';
import { toast } from 'sonner';

export default function ReplicateElementButton({
    bulkCopyTargetElements,
    elements,
    bulkCopyProperties,
    setBulkCopyDialogOpen,
    currentScreenId,
    currentElement,
}: {
    currentScreenId: string;
    currentElement: UIElement;
    bulkCopyTargetElements: string[];
    elements: [
        {
            elementId: string;
            element: UIElement;
            screenId: string;
        },
    ];
    bulkCopyProperties: Record<string, any>;
    setBulkCopyDialogOpen: (open: boolean) => void;
}) {
    const dispatch = useDispatch();
    return (
        <button
            type="button"
            onClick={() => {
                const sourceMetadata = currentElement.metadata;
                bulkCopyTargetElements.forEach((elementId: string) => {
                    let screenId = elementId.split(':')[0];
                    let eId = elementId.split(':')[1];
                    let element = elements.find(
                        (element) =>
                            element.elementId === eId &&
                            element.screenId === screenId,
                    );

                    if (!element) {
                        return;
                    }

                    let copiedMetadata: Record<string, any> = {};
                    bulkCopyProperties.forEach((property: string) => {
                        let propertyValue =
                            sourceMetadata[property as keyof UIElementMetadata];
                        if (propertyValue) {
                            copiedMetadata[property] = propertyValue;
                        }
                    });

                    dispatch(
                        copyUIElementMetadataToUIElement({
                            elementId: element.elementId,
                            screenId: element.screenId,
                            metadata: {
                                ...element.element.metadata,
                                ...copiedMetadata,
                            },
                        }),
                    );
                });
                setBulkCopyDialogOpen(false);
                toast.success('Metadata replicated successfully.');
            }}
            className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
        >
            Replicate
        </button>
    );
}
