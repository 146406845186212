import { TrashIcon } from '@heroicons/react/24/outline';
import PageWithTable from '../Common/components/PageWithTable';
import ServerHealthTable from './ServerHealthTable';
import { authenticatedApi } from '../services/api';
import { toast } from 'sonner';

export default function SystemHealth() {
    const clearTempDirectoryImages = async () => {
        toast.loading('Clearing temp directory images...');

        let results = await authenticatedApi.get(
            '/telemetry/clear-temp-directory-images',
        );

        if (results.status === 200) {
            toast.success('Temp directory images cleared');
        } else {
            toast.error('Failed to clear temp directory images');
        }
    };
    const actions = (
        <button
            onClick={clearTempDirectoryImages}
            className="ml-auto flex items-center gap-x-1 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline cursor-pointer focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
        >
            <TrashIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
            Delete Temp Images
        </button>
    );
    return (
        <PageWithTable title="System Health" actions={actions}>
            <ServerHealthTable />
        </PageWithTable>
    );
}
