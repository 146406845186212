import { useEffect, useState } from 'react';
import api from '@/src/services/api';

export default function usePublicFeatureFlags() {
    const [featureFlags, setFeatureFlags] = useState<{
        [key: string]: boolean;
    }>({});

    useEffect(() => {
        const fetchFlags = async () => {
            const response = await api.get('/public/feature-flags');

            const featureFlags = response.data;
            const flagsDictionary: { [key: string]: boolean } = {};

            for (let flag of featureFlags) {
                flagsDictionary[flag.key] = true;
            }
            console.log('Flags loaded', flagsDictionary);
            setFeatureFlags(flagsDictionary);
        };
        fetchFlags();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        featureFlags,
    };
}
