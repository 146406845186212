import '../../Common/components/Form.css';
import { Formik, Form, Field } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import DatePickerField from '../../Common/components/DatePickerField';
import LocationField from '../../Common/components/LocationField';
import useEditActivation from './hooks/useEditActivation';
import { useEffect, useState } from 'react';
import useGetActivation from './hooks/useActivation';
import Loading from '../../Common/components/Loading';
import { Button } from '@/src/shadComponents/ui/button';

interface ActivationObject {
    id: number;
    name: string;
    date: Date;
    location: string;
    owner: string;
    isArchived: boolean;
    v3Folder: string;
}

export default function EditActivation() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [activation, setActivation] = useState<ActivationObject | null>(null);
    const { id } = useParams();
    const { getActivation } = useGetActivation();
    const { saveActivation } = useEditActivation(id ? +id : 0);

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try {
                const activation = await getActivation(id ? +id : 0);
                setActivation(activation);
            } catch (error) {
                console.error(error);
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Loading isLoading={true} />;
    }

    if (!activation) {
        return <div></div>;
    }

    return (
        <div>
            <div className="Row-container">
                <h1>edit activation</h1>
            </div>
            <Formik
                initialValues={activation}
                onSubmit={async (values, actions) => {
                    const wasSuccessful = await saveActivation(values);
                    actions.setSubmitting(false);

                    if (wasSuccessful) {
                        navigate(-1);
                    }
                }}
            >
                {(props) => (
                    <Form
                        className="Form"
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                    >
                        <div>
                            <label htmlFor="name">name</label>
                            <Field
                                id="name"
                                name="name"
                                placeholder="Activation Name"
                            />
                        </div>
                        <br />
                        <div>
                            <label htmlFor="date">date</label>
                            <DatePickerField id="date" name="date" />
                        </div>
                        <br />
                        <div>
                            <label htmlFor="location">location</label>
                            <LocationField name="location" id="location" />
                        </div>
                        <br />
                        <div>
                            <label htmlFor="v3Folder">v3 folder</label>

                            <div className="mt-2">
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <Field
                                            id="v3Folder"
                                            name="v3Folder"
                                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            placeholder="v3 folder name"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="Layout-row">
                            <Button type="submit" className="Primary-button">
                                save activation
                            </Button>
                            <Button
                                type="button"
                                onClick={() => navigate(-1)}
                                className="Secondary-button"
                            >
                                <span>cancel</span>
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
