import { combineReducers, configureStore } from '@reduxjs/toolkit';
import screens from './state/screensSlice';
import experiences from './state/experienceSlice';
import flows from './state/processingFlowSlice';

import {
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import undoable from 'redux-undo';

const persistConfig = {
    key: 'ps-web-root',
    storage,
};

const rootReducer = combineReducers({
    experiences: undoable(experiences, { limit: 20 }),
    screens: undoable(screens, { limit: 20 }),
    flows: undoable(flows, { limit: 20 }),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
});

const createExperienceStore = () => {
    const persistor = persistStore(store);

    return { store, persistor };
};

export default createExperienceStore;
