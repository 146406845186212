import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import useLogout from '../../Authentication/components/hooks/useLogout';

import Menu from './Menu';
import useFeatureFlags from '../../Features/components/hooks/useFeatureFlags';
import FeatureFlagContext from '../../Features/components/FeatureFlagContext';
import OrgContext from '../../Authentication/components/OrgContext';
import AuthContext from '../../Authentication/components/AuthContext';
import { Toaster } from '@/src/shadComponents/ui/sonner';
import { useLocalStorage } from './hooks/useLocalStorage';

export default function Layout() {
    const logout = useLogout();
    const { featureFlags } = useFeatureFlags();
    const {
        auth: { orgs },
    } = useContext(AuthContext);
    const [org, setOrg] = useLocalStorage('current-org', orgs?.[0]);

    return (
        <OrgContext.Provider value={{ org, setOrg }}>
            <FeatureFlagContext.Provider value={featureFlags}>
                <div className="bg-white min-h-[100vh]" vaul-drawer-wrapper="">
                    <div className="">
                        <header className="absolute inset-x-0 top-0 z-50 flex h-16 border-b border-gray-900/10 bg-white">
                            <div className="mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
                                <Menu
                                    logout={() => {
                                        logout();
                                        setOrg(null);
                                    }}
                                />
                            </div>
                        </header>
                        <main className=" py-0">
                            <div className="relative isolate overflow-hidden pt-16">
                                <Outlet />
                            </div>
                        </main>
                        <Toaster position="bottom-left" />
                    </div>
                </div>
            </FeatureFlagContext.Provider>
        </OrgContext.Provider>
    );
}
