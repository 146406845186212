import { forwardRef, useMemo, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Input } from '../shadComponents/ui/input';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '../shadComponents/ui/popover';
import { Button, ButtonProps } from '../shadComponents/ui/button';
import { useForwardedRef } from './hooks/useForwardRef';
import { cn } from '../lib/utils';

interface ColorPickerProps {
    value: string;
    onChange: (value: string) => void;
    onBlur?: () => void;
}

const ColorPicker = forwardRef<
    HTMLInputElement,
    Omit<ButtonProps, 'value' | 'onChange' | 'onBlur'> & ColorPickerProps
>(
    (
        { disabled, value, onChange, onBlur, name, className, ...props },
        forwardedRef,
    ) => {
        const ref = useForwardedRef(forwardedRef);
        const [open, setOpen] = useState(false);

        const parsedValue = useMemo(() => {
            return value || '#FFFFFF';
        }, [value]);

        return (
            <Popover onOpenChange={setOpen} open={open}>
                <PopoverTrigger asChild disabled={disabled} onBlur={onBlur}>
                    <Button
                        {...props}
                        className={cn('block', className)}
                        name={name}
                        onClick={() => {
                            setOpen(true);
                        }}
                        size="icon"
                        style={{
                            backgroundColor: parsedValue,
                            border: '1px solid #e2e8f0',
                            borderRadius: '6px',
                        }}
                        variant="outline"
                    >
                        <div />
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-full">
                    <HexColorPicker color={parsedValue} onChange={onChange} />
                    <Input
                        maxLength={7}
                        onChange={(e) => {
                            onChange(e?.currentTarget?.value);
                        }}
                        ref={ref}
                        value={parsedValue}
                    />
                </PopoverContent>
            </Popover>
        );
    },
);
ColorPicker.displayName = 'ColorPicker';

export { ColorPicker };
