import { useEffect, useState } from 'react';
import DefinitionParser from '../utils/definitionParser';
import useExperienceState from './useExperienceState';
import { toast } from 'sonner';

export default function useExperienceBuilder(activationId: number) {
    const [definition, setDefinition] = useState<Definition | null>(null);
    const [screenCount, setScreenCount] = useState<number>(0);
    const [processingFlowCount, setProcessingFlowCount] = useState<number>(0);
    const [warnings, setWarnings] = useState<string[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
    const [lastSavedDefinition, setLastSavedDefinition] =
        useState<Definition | null>(null);
    const { loadExperience, screens, exportExperience } =
        useExperienceState(activationId);

    const importDefinition = (definition: string, activationId: number) => {
        let parser = DefinitionParser.getInstance();

        try {
            parser.parseDefinition(definition);
        } catch (e) {
            toast.error('Invalid JSON. Check the format and try again.');
            return;
        }

        let parsedDefinition = parser.parseDefinition(definition);

        let { isValid, warnings, errors, screenCount, processingFlowCount } =
            parser.analyzeDefinition(parsedDefinition);

        setIsValid(isValid);
        setWarnings(warnings);
        setErrors(errors);
        setScreenCount(screenCount);
        setProcessingFlowCount(processingFlowCount);
        setDefinition(parsedDefinition);

        loadExperience(parsedDefinition, activationId);
    };

    const updateDefinition = (definition: Definition) => {
        let parser = DefinitionParser.getInstance();
        let { isValid, warnings, errors, screenCount, processingFlowCount } =
            parser.analyzeDefinition(definition);

        setIsValid(isValid);
        setWarnings(warnings);
        setErrors(errors);
        setProcessingFlowCount(processingFlowCount);
        setScreenCount(screenCount);
        setDefinition(definition);
    };

    /*useEffect(() => {
        setScreenCount(screens.length);
    }, [screens]);*/

    useEffect(() => {
        let interval = setInterval(() => {
            if (definition) {
                let definition = exportExperience(activationId);
                console.log('Definition', definition);

                updateDefinition(definition);
            }
        }, 5000);

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activationId, !!definition]);

    const exportDefinition = () => {
        let parser = DefinitionParser.getInstance();
        return parser.stringifyDefinition(definition);
    };

    useEffect(() => {
        setHasUnsavedChanges(
            definition !== null &&
                JSON.stringify(definition) !==
                    JSON.stringify(lastSavedDefinition),
        );
    }, [definition, lastSavedDefinition]);

    return {
        definition,
        screenCount,
        processingFlowCount,
        warnings,
        errors,
        importDefinition,
        exportDefinition,
        isValid,
        screens,
        updateDefinition,
        hasUnsavedChanges,
        setLastSavedDefinition,
    };
}
