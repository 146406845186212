import { useNavigate } from 'react-router-dom';
import FeatureFlag from '../../Features/components/FeatureFlag';
import { FeatureFlags } from '../../Features/lib/constants';

import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger,
    navigationMenuTriggerStyle,
} from '@/src/shadComponents/ui/navigation-menu';
import React, { useContext } from 'react';
import { cn } from '@/src/lib/utils';

import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerTrigger,
} from '@/src/shadComponents/ui/drawer';
import OrgContext from '@/src/Authentication/components/OrgContext';
import logo from '../../smile_icon.png';
import { GearIcon, PersonIcon } from '@radix-ui/react-icons';
import DropdownMenu from './DropdownMenu';

export default function Menu({ logout }: any) {
    const navigate = useNavigate();
    const orgContext = useContext(OrgContext);

    return (
        <div className="flex flex-row grow">
            <div className="hidden sm:block mt-1 mb-1">
                <NavigationMenu>
                    <NavigationMenuList>
                        <NavigationMenuItem>
                            <NavigationMenuLink
                                onClick={() => {
                                    navigate('/home');
                                }}
                            >
                                <img
                                    className="w-[32px] clickable mr-6"
                                    src={logo}
                                    alt="Smilebooth Logo"
                                />
                            </NavigationMenuLink>
                        </NavigationMenuItem>
                        <FeatureFlag flag={FeatureFlags.JOBS_VIEWER_V1}>
                            <NavigationMenuItem>
                                <NavigationMenuLink
                                    onClick={() => {
                                        navigate('/jobs');
                                    }}
                                    className={
                                        navigationMenuTriggerStyle() +
                                        ' clickable'
                                    }
                                >
                                    Jobs
                                </NavigationMenuLink>
                            </NavigationMenuItem>
                        </FeatureFlag>
                        <FeatureFlag flag={FeatureFlags.ACTIVATIONS_VIEWER_V1}>
                            <NavigationMenuItem>
                                <NavigationMenuLink
                                    onClick={() => {
                                        navigate('/activations');
                                    }}
                                    className={
                                        navigationMenuTriggerStyle() +
                                        ' clickable'
                                    }
                                >
                                    Activations
                                </NavigationMenuLink>
                            </NavigationMenuItem>
                        </FeatureFlag>
                        <FeatureFlag flag={FeatureFlags.GLOBAL_ADMIN_V1}>
                            <NavigationMenuItem>
                                <NavigationMenuTrigger>
                                    Admin
                                </NavigationMenuTrigger>
                                <NavigationMenuContent>
                                    <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-1 lg:w-[600px]">
                                        <ListItem
                                            onClick={() => {
                                                navigate('/admin/orgs');
                                            }}
                                            title="Organizations"
                                            // @ts-ignore
                                            icon="building"
                                        >
                                            Manage organizations.
                                        </ListItem>

                                        <ListItem
                                            onClick={() => {
                                                navigate('/admin/features');
                                            }}
                                            title="Features"
                                            // @ts-ignore
                                            icon="lock"
                                        >
                                            Manage Feature Visibility
                                        </ListItem>
                                        <ListItem
                                            onClick={() => {
                                                navigate(
                                                    '/admin/system-health',
                                                );
                                            }}
                                            title="System Health"
                                            // @ts-ignore
                                            icon="chart-line"
                                        >
                                            System Health
                                        </ListItem>
                                    </ul>
                                </NavigationMenuContent>
                            </NavigationMenuItem>
                        </FeatureFlag>
                        <div className="spacer"></div>
                    </NavigationMenuList>
                </NavigationMenu>
            </div>
            <div className="block sm:hidden">
                <Drawer shouldScaleBackground>
                    <DrawerTrigger asChild>
                        <i className="fa-solid fa-bars mt-[10px]"></i>
                    </DrawerTrigger>
                    <DrawerContent>
                        <div className="mx-auto w-full max-w-sm">
                            <FeatureFlag
                                flag={FeatureFlags.ACTIVATIONS_VIEWER_V1}
                            >
                                <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-1 lg:w-[600px] mobile-menu">
                                    <DrawerClose asChild>
                                        <li
                                            onClick={() => {
                                                navigate('/home');
                                            }}
                                        >
                                            <h4>
                                                <i className="fa-solid fa-gauge mr-1 w-[26px]"></i>
                                                Dashboard
                                            </h4>
                                            <p>
                                                Get a heads-up look at what is
                                                going on, and what you're
                                                assigned to.
                                            </p>
                                        </li>
                                    </DrawerClose>
                                    <DrawerClose asChild>
                                        <li
                                            onClick={() => {
                                                navigate('/activations');
                                            }}
                                        >
                                            <h4>
                                                <i className="fa-solid fa-camera mr-1 w-[26px]"></i>
                                                Activations
                                            </h4>
                                            <p>
                                                Activations contain the
                                                configurations for each photo
                                                experience in Job.
                                            </p>
                                        </li>
                                    </DrawerClose>
                                    <DrawerClose asChild>
                                        <li
                                            onClick={() => {
                                                navigate('/accounts');
                                            }}
                                        >
                                            <h4>
                                                <i className="fa-solid fa-users mr-2 w-[26px]"></i>
                                                Accounts
                                            </h4>
                                            <p>
                                                Manage user accounts including
                                                permissions, passwords, and
                                                more.
                                            </p>
                                        </li>
                                    </DrawerClose>
                                    <DrawerClose asChild>
                                        <li
                                            onClick={() => {
                                                navigate('/device-keys');
                                            }}
                                        >
                                            <h4>
                                                <i className="fa-solid fa-mobile-screen-button w-[26px]"></i>
                                                Devices
                                            </h4>
                                            <p>
                                                Manage your devices, device
                                                keys, and device groups.
                                            </p>
                                        </li>
                                    </DrawerClose>
                                    <DrawerClose asChild>
                                        <li
                                            onClick={() => {
                                                navigate('/templates');
                                            }}
                                        >
                                            <h4>
                                                <i className="fa-solid fa-envelopes-bulk mr-2 w-[26px]"></i>
                                                Email Templates
                                            </h4>
                                            <p>
                                                Import and manage email
                                                templates.
                                            </p>
                                        </li>
                                    </DrawerClose>
                                </ul>
                            </FeatureFlag>
                        </div>
                    </DrawerContent>
                </Drawer>
            </div>
            <div className="grow-[0.99]" />
            <div className="align-middle flex row content-center flex-wrap ">
                <DropdownMenu titleIcon={<GearIcon className="mx-2" />}>
                    <FeatureFlag flag={FeatureFlags.ACCOUNT_ADMIN_V1}>
                        <span
                            className="clickable text-gray-700 group flex items-center px-4 py-2 text-sm"
                            onClick={() => {
                                navigate('/accounts');
                            }}
                        >
                            Accounts
                        </span>
                    </FeatureFlag>
                    <FeatureFlag flag={FeatureFlags.DEVICE_VIEWER_V1}>
                        <span
                            className="clickable text-gray-700 group flex items-center px-4 py-2 text-sm"
                            onClick={() => {
                                navigate('/device-management');
                            }}
                        >
                            Devices
                        </span>
                    </FeatureFlag>
                    <span
                        className="clickable text-gray-700 group flex items-center px-4 py-2 text-sm"
                        onClick={() => {
                            navigate('/templates');
                        }}
                    >
                        Email Templates
                    </span>
                </DropdownMenu>
                <DropdownMenu titleIcon={<PersonIcon className="mx-2" />}>
                    <div
                        className="clickable text-gray-700 group flex items-center px-4 py-2 text-sm"
                        onClick={() => {
                            navigate('/org');
                        }}
                    >
                        <span className="">{orgContext.org?.name}</span>
                        <div className="spacer" />
                        <i className="fa-solid fa-building"></i>
                    </div>
                    {/*<div
                    onClick={() => logout()}
                    className="clickable text-gray-700 group flex items-center px-4 py-2 text-sm"
                >
                    <span className="clickable">Your Profile&nbsp;&nbsp;</span>
                    <div className="spacer" />
                    <i className="fa-solid fa-user"></i>
                    </div>*/}
                    <div
                        onClick={() => logout()}
                        className="clickable text-gray-700 group flex items-center px-4 py-2 text-sm"
                    >
                        <span className="clickable">Logout&nbsp;&nbsp;</span>
                        <div className="spacer" />
                        <i className="fa-solid fa-sign-out"></i>
                    </div>
                </DropdownMenu>
            </div>
        </div>
    );
}

const ListItem = React.forwardRef<
    React.ElementRef<'a'>,
    React.ComponentPropsWithoutRef<'a'>
>(({ className, title, children, ...props }, ref) => {
    return (
        <li>
            <NavigationMenuLink asChild>
                <a
                    ref={ref}
                    className={cn(
                        'block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
                        className,
                    )}
                    {...props}
                >
                    <div className="text-sm font-medium leading-none py-[6px]">
                        <i
                            // @ts-ignore
                            className={`fa-solid fa-${props.icon} mr-2 w-[26px]`}
                        ></i>
                        {title}
                    </div>
                    <p className="line-clamp-2 px-[38px] text-sm leading-snug text-muted-foreground nav-description">
                        {children}
                    </p>
                </a>
            </NavigationMenuLink>
        </li>
    );
});
