import usePublicFeatureFlags from './hooks/usePublicFeatureFlags';
import { Outlet } from 'react-router-dom';
import FeatureFlagContext from './FeatureFlagContext';
export default function Layout() {
    const { featureFlags } = usePublicFeatureFlags();

    return (
        <FeatureFlagContext.Provider value={featureFlags}>
            <Outlet />
        </FeatureFlagContext.Provider>
    );
}
