import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ScreenState {
    entities: Record<string, ScreenDefinition>;
    ids: string[];
}

const initialState: ScreenState = {
    entities: {},
    ids: [],
};

const generateCaptureSettingsMetadata = (): CaptureMetadata => {
    return {
        mode: 'clicker',
        flowId: 0,
        shouldNavigateAfterCapture: false,
        postCaptureDestination: '',
        photoCount: null,
        prompt: '',
        countDown: null,
        processingMessage: null,
        captureMessage: null,
        dynamicTextIdentifier: null,
        captureOnMount: false,
        skipGifGeneration: false,
        gifDuration: null,
        gifCaptureDuration: null,
        timeout: null,
        videoProcessingFlowId: null,
        postCaptureAction: null,
        valueKey: null,
        navigateAfterCaptureDelay: 0,
    };
};

const screenSlice = createSlice({
    name: 'screen',
    initialState,
    reducers: {
        addScreen(state, action) {
            const screen = action.payload;
            const id = screen.id;

            let entities = { ...state.entities };
            entities[id] = screen;
            state.entities = entities;

            let ids = [...state.ids];
            if (!ids.includes(id)) {
                ids.push(id);
            }
            state.ids = ids;
        },
        createScreen(state, action) {
            const screen = action.payload;
            const id = screen.id;
            const newScreen = {
                id,
                elements: [],
                displayProperties: {
                    backgroundColor: '#FFFFFF',
                    foregroundColor: '#000000',
                    backgroundMediaUrl: '',
                    buttonColor: '#000000',
                    buttonTextColor: '#FFFFFF',
                    horizontalPadding: 0,
                    verticalPadding: 0,
                },
                stage: {
                    type: 'attract' as StageType.Attract,
                    metadata: {},
                },
                metadata: {},
            };

            let entities = { ...state.entities };
            entities[id] = newScreen;
            state.entities = entities;

            let ids = [...state.ids];
            if (!ids.includes(id)) {
                ids.push(id);
            }
            state.ids = ids;
        },
        removeScreen(state, action) {
            const { id } = action.payload;

            delete state.entities[id];
            state.ids = state.ids.filter((screenId) => screenId !== id);
        },
        resetScreens(state) {
            state.entities = {};
            state.ids = [];
        },
        updateScreenId(state, action) {
            const { id, newId } = action.payload;

            state.entities[newId] = { ...state.entities[id], id: newId };
            delete state.entities[id];
            state.ids = state.ids.map((screenId) =>
                screenId === id ? newId : screenId,
            );
        },
        updateScreen(state, action) {
            const { id, screen } = action.payload;

            state.entities[id] = screen;
        },
        updateScreenStage(state, action) {
            const { id, stage } = action.payload;

            const screen = state.entities[id];

            if (!screen) {
                return;
            }

            if (screen.stage.type === stage.type) {
                return;
            }

            let newScreen = { ...screen, stage };

            if (stage.type === 'capture') {
                newScreen.stage.metadata = generateCaptureSettingsMetadata();
            } else {
                newScreen.stage.metadata = {};
            }

            state.entities[id] = newScreen;
        },
        updateScreenMetadata(state, action) {
            const { id, metadata } = action.payload;

            state.entities[id].stage.metadata = metadata;
        },
        updateScreenDisplayProperties(state, action) {
            const { id, displayProperties } = action.payload;

            state.entities[id].displayProperties = displayProperties;
        },
        updateScreenUIElement(state, action) {
            const { screenId, key, value, elementId } = action.payload;

            const elementIndex = state.entities[screenId].elements.findIndex(
                (element) => element.id === elementId,
            );

            const element = state.entities[screenId].elements[elementIndex];

            if (!element) {
                return;
            }
            state.entities[screenId].elements[elementIndex] = {
                ...element,
                [key]: value,
            };
        },
        updateScreenUIElementMetadata(state, action) {
            const { screenId, elementId, key, value } = action.payload;
            const elementIndex = state.entities[screenId].elements.findIndex(
                (element) => element.id === elementId,
            );

            const element = state.entities[screenId].elements[elementIndex];

            if (!element) {
                return;
            }

            const screen = state.entities[screenId];

            if (!screen) {
                return;
            }

            const newScreen = {
                ...screen,
                displayProperties: {
                    ...screen.displayProperties,
                },
                elements: [
                    ...screen.elements.map((element) => {
                        if (element.id === elementId) {
                            return {
                                ...element,
                                metadata: {
                                    ...element.metadata,
                                    [key]: value,
                                },
                            };
                        }
                        return element;
                    }),
                ],
            };

            state.entities[screenId] = newScreen;
        },
        createUIElement(
            state,
            action: PayloadAction<{
                screenId: string;
                horizontalLocation: string;
                verticalLocation: string;
            }>,
        ) {
            const { screenId, verticalLocation, horizontalLocation } =
                action.payload;

            const element = {
                id: `element-${screenId}-${state.entities[screenId].elements.length}`,
                verticalLocation,
                horizontalLocation,
                order: 0,
                type: 'text',
                metadata: {
                    type: 'text',
                    text: 'Text Element',
                    fontSize: 16,
                    fontWeight: 400,
                    verticalOffset: 0,
                    horizontalOffset: 0,
                },
            } as unknown as UIElement;

            state.entities[screenId].elements.push(element);
        },
        changeUIElementType(
            state,
            action: PayloadAction<{
                screenId: string;
                elementId: string;
                type: UIElementType;
            }>,
        ) {
            const { screenId, elementId, type } = action.payload;

            const elementIndex = state.entities[screenId].elements.findIndex(
                (element) => element.id === elementId,
            );

            const element = state.entities[screenId].elements[elementIndex];

            if (!element) {
                return;
            }

            const prevType = element?.type;

            if (prevType === type) {
                return;
            }

            const oldMetadata = element.metadata;
            let newMetadata: UIElementMetadata = {
                id: element.id,
                type,
                verticalOffset: oldMetadata.verticalOffset,
                horizontalOffset: oldMetadata.horizontalOffset,
            };

            // Not a fan of this method, but it's a quick fix for now.
            // TODO: Refactor this to be more maintainable.
            switch (type) {
                case 'text':
                    newMetadata = {
                        ...newMetadata,
                        text: '',
                        fontSize: 16,
                        fontWeight: 400,
                        fontFace: null,
                        horizontalMargin: 0,
                        verticalMargin: 0,
                        horizontalOffset: 0,
                        verticalOffset: 0,
                    } as unknown as UITextMetadata;
                    break;

                case 'dynamicText':
                    newMetadata = {
                        ...newMetadata,
                        identifier: '',
                        fontSize: 16,
                        fontWeight: 400,
                        fontFace: null,
                        horizontalMargin: 0,
                        verticalMargin: 0,
                        horizontalOffset: 0,
                        verticalOffset: 0,
                    } as unknown as UIDynamicTextMetadata;
                    break;

                case 'button':
                    newMetadata = {
                        ...newMetadata,
                        text: 'Button',
                        action: 'navigate',
                        imageUrl: null,
                        destination: null,
                        value: null,
                        valueKey: null,
                        fontWeight: 400,
                        fontSize: 16,
                        fontFace: null,
                        horizontalMargin: 0,
                        verticalMargin: 0,
                        horizontalPadding: 0,
                        verticalPadding: 0,
                        borderSize: 0,
                        borderColor: '#000000',
                        cornerRadius: 0,
                        keyPressTrigger: null,
                        volumeUpTrigger: false,
                        horizontalOffset: 0,
                        verticalOffset: 0,
                    } as unknown as UIButtonMetadata;
                    break;

                case 'image':
                    newMetadata = {
                        ...newMetadata,
                        imageUrl: '',
                        width: 100,
                        height: 100,
                        cornerRadius: 0,
                        horizontalOffset: 0,
                        verticalOffset: 0,
                    } as UIImageMetadata;
                    break;

                case 'outputPreview':
                    newMetadata = {
                        ...newMetadata,
                        width: 100,
                        height: 100,
                        scene: '',
                        cornerRadius: 0,
                        horizontalOffset: 0,
                        verticalOffset: 0,
                    } as UIOutputPreviewMetadata;
                    break;

                case 'input':
                    newMetadata = {
                        ...newMetadata,
                        key: oldMetadata.key || '',
                        inputType: 'text',
                        label: null,
                        cornerRadius: 0,
                        width: 100,
                        fontFace: '',
                        fontSize: 16,
                        fontWeight: 400,
                        defaultValue: '',
                        isFocused: false,
                        horizontalOffset: 0,
                        verticalOffset: 0,
                    } as UIInputMetadata;
                    break;

                case 'currentSessionQR':
                    newMetadata = {
                        ...newMetadata,
                        width: 100,
                        height: 100,
                        horizontalMargin: 0,
                        verticalMargin: 0,
                        foregroundColor: '#000000',
                        backgroundColor: '#FFFFFF',
                        horizontalOffset: 0,
                        verticalOffset: 0,
                    } as UICurrentSessionQRMetadata;
                    break;

                default:
                    // For any other types, keep the base UIElementMetadata
                    break;
            }

            state.entities[screenId].elements[elementIndex].type = type;
            state.entities[screenId].elements[elementIndex].metadata =
                newMetadata;
        },
        deleteUIElement(state, action) {
            const { screenId, elementId } = action.payload;
            const screen = { ...state.entities[screenId] };

            if (!screen) {
                return;
            }

            screen.elements = screen.elements.filter(
                (element) => element.id !== elementId,
            );

            state.entities[screenId] = screen;
        },
        deleteScreen(state, action) {
            const { screenId } = action.payload;

            delete state.entities[screenId];
            state.ids = state.ids.filter((id) => id !== screenId);
        },
        copyDisplayPropertiesToScreen(state, action) {
            const { screenId, displayProperties } = action.payload;

            const screen = { ...state.entities[screenId] };

            if (!screen) {
                return;
            }
            state.entities[screenId].displayProperties = {
                ...screen.displayProperties,
                ...displayProperties,
            };
        },
        copyUIElementMetadataToUIElement(state, action) {
            const { screenId, elementId, metadata } = action.payload;

            const screen = { ...state.entities[screenId] };
            const elements = [...screen.elements];
            const elementIndex = elements.findIndex(
                (element) => element.id === elementId,
            );
            const element = { ...elements[elementIndex] };

            if (!element) {
                return;
            }

            let newElement = {
                ...element,
                metadata: {
                    ...element.metadata,
                    ...metadata,
                },
            };
            elements[elementIndex] = newElement;
            screen.elements = elements;
            state.entities[screenId] = screen;
        },
    },
});

export const {
    addScreen,
    createScreen,
    removeScreen,
    resetScreens,
    updateScreenMetadata,
    updateScreenStage,
    updateScreen,
    updateScreenId,
    updateScreenDisplayProperties,
    updateScreenUIElement,
    updateScreenUIElementMetadata,
    createUIElement,
    changeUIElementType,
    deleteUIElement,
    deleteScreen,
    copyDisplayPropertiesToScreen,
    copyUIElementMetadataToUIElement,
} = screenSlice.actions;

export default screenSlice.reducer;
