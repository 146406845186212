import React, { useEffect, useState } from 'react';
import '../../Common/components/Form.css';
import { Formik, Form, Field } from 'formik';
import useFeatureFlagManagement from './hooks/useFeatureFlagManagement';
import { useNavigate, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import Loading from '../../Common/components/Loading';
import { Button } from '@/src/shadComponents/ui/button';

interface FeatureFlag {
    key: string;
    enabled: boolean;
    enabledForDevelopment: boolean;
    isPublic: boolean;
}

export default function EditFeatureFlag() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [featureFlag, setFeatureFlag] = useState<FeatureFlag | null>(null);
    const { updateFeatureFlag, getFeatureFlag, isLoading, setIsLoading } =
        useFeatureFlagManagement();

    useEffect(() => {
        let isMounted = true;

        const load = async () => {
            try {
                const featureFlag = await getFeatureFlag(id ? +id : 0);
                setFeatureFlag(featureFlag);
            } catch (e) {
                console.error('Feature flag not found');
            } finally {
                isMounted && setIsLoading(false);
            }
        };

        load();

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <Loading isLoading={isLoading} />;
    }

    if (!featureFlag) {
        return <div>Feature flag not found</div>;
    }

    return (
        <div>
            <div className="Row-container">
                <h1>Edit feature flag</h1>
            </div>
            <Formik
                initialValues={featureFlag}
                onSubmit={async (values, actions) => {
                    const wasSuccessful = await updateFeatureFlag(values);
                    actions.setSubmitting(false);

                    if (wasSuccessful) {
                        navigate('/admin/features');
                    }
                }}
            >
                <Form
                    className="Form"
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                >
                    <div>
                        <label htmlFor="key">Key</label>
                        <Field id="key" name="key" placeholder="" />
                    </div>
                    <br />
                    <div>
                        <label htmlFor="enabled">Enabled</label>
                        <div className="left-justify">
                            <Field
                                id="enabled"
                                name="enabled"
                                type="checkbox"
                            />
                        </div>
                    </div>
                    <br />
                    <div>
                        <label htmlFor="enabledForDevelopment">
                            Development Enabled
                        </label>
                        <div className="left-justify">
                            <Field
                                id="enabledForDevelopment"
                                name="enabledForDevelopment"
                                type="checkbox"
                            />
                        </div>
                    </div>
                    <br />
                    <div>
                        <label htmlFor="isPublic">Is Public</label>
                        <div className="left-justify">
                            <Field
                                id="isPublic"
                                name="isPublic"
                                type="checkbox"
                            />
                        </div>
                    </div>
                    <br />
                    <div className="Layout-row">
                        <Button type="submit" className="Primary-button">
                            Update Feature Flag
                        </Button>
                        <NavLink to="/admin/features">
                            <Button className="Secondary-button">Cancel</Button>
                        </NavLink>
                    </div>
                </Form>
            </Formik>
        </div>
    );
}
