import { createContext } from 'react';

export const ExperienceBuilderContext = createContext<{
    selectedScreenId: string | null;
    setSelectedScreenId: (id: string | null) => void;
    selectedElementIndex: number | null;
    setSelectedElementIndex: (index: number | null) => void;
    bulkCopyMode: boolean;
    setBulkCopyMode: (mode: boolean) => void;
    bulkCopyProperties: string[];
    setBulkCopyProperties: (properties: string[]) => void;
    bulkCopyDialogOpen: boolean;
    setBulkCopyDialogOpen: (open: boolean) => void;
    bulkCopyTargetScreens: string[];
    setBulkCopyTargetScreens: (screens: string[]) => void;
    bulkCopyTargetElements: string[];
    setBulkCopyTargetElements: (elements: string[]) => void;
}>({
    selectedScreenId: null,
    setSelectedScreenId: () => {},
    selectedElementIndex: null,
    setSelectedElementIndex: () => {},
    bulkCopyMode: false,
    setBulkCopyMode: () => {},
    bulkCopyProperties: [],
    setBulkCopyProperties: () => {},
    bulkCopyDialogOpen: false,
    setBulkCopyDialogOpen: () => {},
    bulkCopyTargetScreens: [],
    setBulkCopyTargetScreens: () => {},
    bulkCopyTargetElements: [],
    setBulkCopyTargetElements: () => {},
});
