import React, { useCallback, useEffect, useMemo } from 'react';
import {
    ReactFlow,
    ReactFlowProvider,
    Panel,
    useNodesState,
    useEdgesState,
    useReactFlow,
    Handle,
    Position,
} from '@xyflow/react';
import ELK from 'elkjs';

import DefinitionParser from './utils/definitionParser';

import '@xyflow/react/dist/style.css';

const elk = new ELK();

const useLayoutedElements = () => {
    const { getNodes, setNodes, getEdges, fitView } = useReactFlow();
    const defaultOptions = {
        'elk.algorithm': 'layered',
        'elk.direction': 'DOWN',
        'elk.layered.spacing.nodeNodeBetweenLayers': 200,
        'elk.spacing.nodeNode': 200,
    };

    // @ts-ignore
    const getLayoutedElements = useCallback((options) => {
        const layoutOptions = { ...defaultOptions, ...options };
        const graph = {
            id: 'root',
            layoutOptions: layoutOptions,
            children: getNodes().map((node) => ({
                ...node,
                // @ts-ignore
                width: node.measured.width,
                // @ts-ignore
                height: node.measured.height,
            })),
            edges: getEdges(),
        };

        // @ts-ignore
        elk.layout(graph).then(({ children }) => {
            // By mutating the children in-place we saves ourselves from creating a
            // needless copy of the nodes array.
            // @ts-ignore
            children.forEach((node) => {
                // @ts-ignore
                node.position = { x: node.x, y: node.y };
            });

            // @ts-ignore
            setNodes(children);
            window.requestAnimationFrame(() => {
                fitView();
            });
        });

        // eslint-disable-next-line
    }, []);

    return { getLayoutedElements };
};

function ExperienceGraph({
    screens,
    onScreenSelected,
}: {
    screens: ScreenDefinition[];
    onScreenSelected: (screenId: string) => void;
}) {
    // eslint-disable-next-line
    const { screenGraph, edges: initialEdges } = useMemo(() => {
        const { screenGraph, edges } =
            DefinitionParser.getInstance().generateScreenGraph(
                Object.values(screens),
            );
        return { screenGraph, edges };

        // eslint-disable-next-line
    }, [screens.length]);

    const [nodes, setNodes, onNodesChange] = useNodesState(
        Object.values(screens).map((screen, index) => ({
            id: screen.id,
            position: { x: 0, y: 0 },
            data: { label: screen.id, ...screen },
            type: 'screen',
        })),
    );
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const { getLayoutedElements } = useLayoutedElements();

    useEffect(() => {
        console.log('Screen count', screens.length);
        setNodes(
            Object.values(screens).map((screen, index) => ({
                id: screen.id,
                position: { x: 0, y: 0 },
                data: { label: screen.id, ...screen },
                type: 'screen',
            })),
        );

        setEdges(initialEdges);
        setTimeout(() => {
            getLayoutedElements({
                'elk.algorithm': 'layered',
                'elk.direction': 'DOWN',
            });
        }, 500);
        // eslint-disable-next-line
    }, [screens.length]);

    const nodeTypes = useMemo(() => {
        const nodeTypes = {
            screen: (node: any) => (
                <ScreenCard
                    onSelected={() => onScreenSelected(node.data.label)}
                >
                    <div className="bg-white p-2 rounded-md absolute top-0 left-0 text-black font-2xl w-full border-[2px] rounded-t-2xl  border-gray-300">
                        ID: {node.data.label}
                    </div>
                    <div
                        className="w-full h-full mt-10 flex "
                        style={{
                            backgroundColor:
                                node.data.displayProperties.backgroundColor,
                            color: node.data.displayProperties.foregroundColor,
                            backgroundImage: `url(${node.data.displayProperties.backgroundMediaUrl})`,
                            backgroundSize: 'cover',
                            paddingTop:
                                (node.data.displayProperties.verticalPadding ||
                                    0) /
                                    2 +
                                'px',
                            paddingBottom:
                                (node.data.displayProperties.verticalPadding ||
                                    0) /
                                    2 +
                                'px',
                            paddingLeft:
                                (node.data.displayProperties
                                    .horizontalPadding || 0) /
                                    2 +
                                'px',
                            paddingRight:
                                (node.data.displayProperties
                                    .horizontalPadding || 0) /
                                    2 +
                                'px',
                        }}
                    ></div>
                </ScreenCard>
            ),
        };
        return nodeTypes;
        // eslint-disable-next-line
    }, []);

    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            fitView
            nodeTypes={nodeTypes}
            panOnScroll
            selectionOnDrag
        >
            <Panel>
                <div />
            </Panel>
        </ReactFlow>
    );
}

let memoizedStyle = {
    height: 'calc(100vh - 200px)',
};

export default function ExperienceGraphWrapper({
    screens,
    onScreenSelected,
}: {
    screens: ScreenDefinition[];
    onScreenSelected: (screenId: string) => void;
}) {
    return (
        <ReactFlowProvider>
            <div style={memoizedStyle}>
                <ExperienceGraph
                    screens={screens}
                    onScreenSelected={onScreenSelected}
                />
            </div>
        </ReactFlowProvider>
    );
}

const ScreenCard = ({
    children,
    onSelected,
}: {
    children: React.ReactNode;
    onSelected: () => void;
}) => {
    return (
        <div className="overflow-hidden bg-[#EEEFEF] shadow-md rounded-2xl w-[280px]">
            <div
                className="flex flex-col items-center justify-center h-[400px]"
                onClick={onSelected}
            >
                {children}
            </div>
            {/* @ts-ignore */}
            <Handle
                type="target"
                position={Position.Top}
                className="w-4 h-4 !bg-black-500"
            />
            {/* @ts-ignore */}
            <Handle
                type="source"
                position={Position.Bottom}
                className="w-4 h-4 !bg-black-500"
            />
        </div>
    );
};
