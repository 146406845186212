import Loading from '../../Common/components/Loading';
import './Gallery.css';
import logo from './white_logo.png';
import GalleryPreviewImage from './GalleryPreviewImage';
import { useEffect, useState } from 'react';
import FindMyPhotosModal from './FindMyPhotosModal';
import useGalleryViewLogger from '../../Stats/components/hooks/useGalleryViewLogger';
import MetaPixel from '@/src/Common/components/MetaPixel';
import { Masonry } from 'masonic';

interface MasonryGalleryProps {
    gallery: GalleryObject | null;
    isLoading?: boolean;
    photos: MediaInstance[];
    delayed?: boolean;
    expandedIndex: number;
    setExpandedIndex: (expandedIndex: number) => void;
    swipeHandlers: any;
    loadedPhotos: MediaInstance[];
    getNewPage: (size: number) => void;
    message?: string | React.ReactNode;
}

export default function MasonryGallery({
    gallery,
    isLoading = false,
    photos,
    delayed = true,
    expandedIndex,
    setExpandedIndex,
    swipeHandlers,
    loadedPhotos,
    getNewPage,
    message = '',
}: MasonryGalleryProps) {
    const [showFindMyPhotosModal, setShowFindMyPhotosModal] =
        useState<boolean>(false);

    const hasCTA = gallery?.ctaImage || gallery?.ctaText;
    const galleryId = gallery?.id || 0;
    const { logGalleryView } = useGalleryViewLogger();

    useEffect(() => {
        if (galleryId) {
            logGalleryView(galleryId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [galleryId]);

    const GalleryPreviewImageWrapper = ({
        data,
        index,
    }: {
        data: MediaInstance;
        index: number;
    }) => {
        return (
            <GalleryPreviewImage
                media={data}
                setExpandedIndex={setExpandedIndex}
                index={index}
                isExpanded={expandedIndex === index}
            />
        );
    };

    return (
        <>
            <Loading isLoading={isLoading} />
            {!isLoading && (
                <div
                    className={`Gallery-header ${
                        expandedIndex !== -1 ? 'hidden invisible' : ''
                    }`}
                >
                    <div className="Gallery-header-title">
                        <img
                            src={gallery?.logo || logo}
                            alt="Smilebooth Logo"
                        />
                    </div>
                    <div className="Gallery-name">{gallery?.name}</div>
                </div>
            )}
            <div
                className={`Gallery-content ${
                    hasCTA ? 'pb-[100px]' : 'pb-[20px]'
                }`}
                id="galleryContainer"
            >
                {gallery?.metaPixelId && (
                    <MetaPixel pixelId={gallery?.metaPixelId} />
                )}

                <div className="Gallery-grid-container">
                    <div
                        {...swipeHandlers}
                        className={`Gallery-grid ${delayed ? '' : 'hide'}`}
                    >
                        <Masonry
                            items={photos}
                            render={GalleryPreviewImageWrapper}
                            columnGutter={12}
                            rowGutter={12}
                            maxColumnCount={5}
                            overscanBy={6}
                            columnWidth={
                                photos.length > 3
                                    ? 400
                                    : photos.length > 1
                                    ? 784
                                    : 1080
                            }
                        />
                    </div>
                </div>

                {message ? (
                    <div className="Gallery-message">
                        <p>{message}</p>
                    </div>
                ) : null}
            </div>
            {hasCTA && expandedIndex === -1 ? (
                <div
                    className="cta-container fixed bottom-0 left-0 right-0 flex justify-center max-h-[100px] items-center cursor-pointer clickable"
                    onClick={() => {
                        if (gallery?.ctaLink) {
                            window.open(gallery?.ctaLink, '_blank');
                        }
                    }}
                >
                    {gallery?.ctaImage && (
                        <img
                            src={gallery?.ctaImage}
                            alt="Smilebooth CTA"
                            className="resizable"
                        />
                    )}
                    {gallery?.ctaText && <p>{gallery?.ctaText}</p>}
                </div>
            ) : null}
            {/*<div
                className={`Find-your-own-photos-banner clickable ${
                    expandedIndex > -1 ? 'hidden' : ''
                }`}
                onClick={() => setShowFindMyPhotosModal(true)}
            >
                <h3 className="looking-for-prompt">
                    Looking for your own photos?
                </h3>
            </div>
            */}
            {showFindMyPhotosModal ? (
                <FindMyPhotosModal
                    setShowModal={(show) => setShowFindMyPhotosModal(show)}
                />
            ) : null}
        </>
    );
}
