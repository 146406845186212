import { useState, useEffect } from 'react';
import api from '../../../services/api';

const SURVEY_TOKEN_CHECK_INTERVAL = 2000;

export default function useExternalSurvey(
    galleryInfo: GalleryObject | null,
    forceTokenCheck = false,
) {
    const [surveyUrl, setSurveyUrl] = useState<string | null>(null);
    const [surveyToken, setSurveyToken] = useState<string | null>(null);
    const [hasTokenBeenClaimed, setHasTokenBeenClaimed] = useState(false);
    const [hasSurveyBeenCompleted, setHasSurveyBeenCompleted] = useState(false);
    const [hasCompletionCheckOccurred, setHasCompletionCheckOccurred] =
        useState(forceTokenCheck);

    const checkSurveyCompletion = async (destinationIdentifier: string) => {
        const response = await api
            .get(
                `/share/activation/${
                    galleryInfo?.activationId
                }/destination/${encodeURIComponent(
                    destinationIdentifier,
                )}/has-survey-been-completed`,
            )
            .catch((error) => {
                console.error('Error checking survey completion', error);
            });

        if (response && response.data.completed) {
            setHasSurveyBeenCompleted(response.data.completed);
        }

        setHasCompletionCheckOccurred(true);
    };

    useEffect(() => {
        if (galleryInfo?.surveyUrl) {
            setSurveyUrl(galleryInfo.surveyUrl);
        }
    }, [galleryInfo]);

    const generateSurveyToken = async () => {
        const response = await api.post(
            `/share/activation/${galleryInfo?.activationId}/survey-token`,
        );

        const { data } = response;
        return data;
    };

    const getSurveyToken = async () => {
        const response = await api.get(
            `/share/activation/${galleryInfo?.activationId}/survey-token/${surveyToken}`,
        );

        return response;
    };

    useEffect(() => {
        let isMounted = true;

        const fetchSurveyToken = async () => {
            if (
                surveyUrl &&
                hasCompletionCheckOccurred &&
                !hasSurveyBeenCompleted
            ) {
                const tokenResponse = await generateSurveyToken();
                isMounted && setSurveyToken(tokenResponse.token);
            }
        };

        if (surveyUrl) {
            fetchSurveyToken();
        }

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [surveyUrl, hasCompletionCheckOccurred, hasSurveyBeenCompleted]);

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (surveyToken) {
            interval = setInterval(async () => {
                const response = await getSurveyToken();

                if (response.data.usedAt) {
                    setHasTokenBeenClaimed(true);
                    clearInterval(interval);
                }
            }, SURVEY_TOKEN_CHECK_INTERVAL);
        }

        return () => {
            interval && clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [surveyToken]);

    const markSurveyTokenAsUsed = async () => {
        const response = await api.post(
            `/share/activation/${galleryInfo?.activationId}/survey-token/${surveyToken}/mark-as-used`,
        );

        const { data } = response;
        return data;
    };

    return {
        surveyUrl,
        surveyToken,
        markSurveyTokenAsUsed,
        hasTokenBeenClaimed,
        hasSurveyBeenCompleted,
        checkSurveyCompletion,
    };
}
