import { useCallback, useState } from 'react';
import useAuthenticatedApi from '../../../Authentication/components/hooks/useAuthenticatedApi';

const parseSurveyResults = (results: any[]) => {
    // flatten the results so that each field in the response is a top level key
    return results.map((result) => {
        return {
            destination: result.destination,
            activationId: result.activationId,
            ...result.response,
        };
    });
};

export default function useSurveyResults() {
    const authenticatedApi = useAuthenticatedApi();
    const [surveyResults, setSurveyResults] = useState<any[]>([]);

    const getSurveyResults = useCallback(
        async (activationId: number) => {
            const { data } = await authenticatedApi.get(
                `/surveys/activation/${activationId}/responses`,
            );
            return data;
        },
        [authenticatedApi],
    );

    const loadSurveyResults = useCallback(
        async (activationId: number) => {
            const results = await getSurveyResults(activationId);

            console.log('results', results);
            const parsedResults = parseSurveyResults(results);
            console.log('parsedResults', parsedResults);

            setSurveyResults(parsedResults);
        },
        [getSurveyResults],
    );

    return {
        getSurveyResults,
        loadSurveyResults,
        surveyResults,
    };
}
