import {
    useState,
    useEffect,
    useMemo,
    useRef,
    useCallback,
    useContext,
} from 'react';
import GalleryContext from '@/src/Gallery/components/GalleryContext';
import logo from './white_logo.png';
import Loading from '@/src/Common/components/Loading';
import GalleryLightbox from './GalleryLightbox';
import useGalleryBehaviors from './hooks/useGalleryBehvaiors';
import useQueryString from '@/src/Common/components/hooks/useQueryString';
import { useSearchParams } from 'react-router-dom';

interface AbsoluteGalleryProps {
    gallery: GalleryObject | null;
    isLoading?: boolean;
    photos: MediaInstance[];
    expandedIndex: number;
    loadedPhotos: MediaInstance[];
    getNewPage: (size: number) => void;
    message?: string | React.ReactNode;
}

let columnHeights: number[] = [];

export default function AbsoluteGallery({
    gallery,
    isLoading = false,
    photos,
}: AbsoluteGalleryProps) {
    const [columns, setColumns] = useState<number>(1);
    const containerRef = useRef<HTMLDivElement>(null);
    const GAP = 0; // Gap between images in pixels
    const [columnWidth, setColumnWidth] = useState<number>(0);
    const hasCTA = gallery?.ctaImage || gallery?.ctaText;
    const query = useQueryString();
    const urlShareIdentifier = query.get('sid');

    // Calculate number of columns based on container width
    useEffect(() => {
        const updateColumns = () => {
            const width = containerRef.current?.offsetWidth ?? 0;
            if (width < 1200) {
                setColumns(2);
                setColumnWidth(width / 2);
            } else if (width < 1800) {
                setColumns(3);
                setColumnWidth(width / 3);
            } else {
                setColumns(4);
                setColumnWidth(width / 4);
            }
        };

        updateColumns();
        window.addEventListener('resize', updateColumns);
        return () => window.removeEventListener('resize', updateColumns);
    }, []);

    // Calculate positions for each photo
    const getPhotoPositions = useMemo(() => {
        columnHeights = new Array(columns).fill(0);

        return photos.map((photo, index) => {
            // @ts-ignore
            const aspectRatio =
                // @ts-ignore
                photo.aspectRatio ?? 1.5;
            const width = `${columnWidth}px`;
            const shortestColumn = columnHeights.indexOf(
                Math.min(...columnHeights),
            );
            const x = `${
                shortestColumn * (100 / columns) + shortestColumn * GAP
            }%`;
            const y = columnHeights[shortestColumn];

            const photoHeight = columnWidth / aspectRatio;
            columnHeights[shortestColumn] += photoHeight + GAP;

            return {
                id: photo.id,
                style: {
                    position: 'absolute',
                    left: x,
                    top: y,
                    width,
                    height: `${photoHeight}px`,
                    display: 'flex',
                },
                shareIdentifier: photo.shareIdentifier,
                thumbnail: photo.thumbnail_800pxUri,
                full: photo.originalUri,
                tiny: photo.thumbnailTinyUri,
                imageStyle: {
                    objectFit: 'contain',
                    backgroundSize: 'cover',
                    backgroundColor: 'rgba(0,0,0,0.05)',
                    transition: 'all 0.3s linear',
                    margin: 4,
                    flex: 1,
                },
            };
        });
    }, [photos, columns, columnWidth]);

    const [shareIdentifier, setShareIdentifier] = useState<string | null>(
        urlShareIdentifier ?? null,
    );
    const [photoIndex, setPhotoIndex] = useState<number>(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const currentSearchParams = Object.fromEntries(
        Array.from(searchParams.entries()).filter(
            ([key]: [string, string]) => key !== 'sid',
        ),
    );

    useEffect(() => {
        if (shareIdentifier) {
            setPhotoIndex(
                photos.findIndex((p) => p.shareIdentifier === shareIdentifier),
            );

            setSearchParams({ ...currentSearchParams, sid: shareIdentifier });

            const element = document.getElementById(
                `${shareIdentifier}-element`,
            );
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            setSearchParams({
                ...currentSearchParams,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shareIdentifier, photos, setPhotoIndex, setSearchParams, searchParams]);

    const { swipeHandlers } = useGalleryBehaviors(
        () => {
            setShareIdentifier(null);
        },
        () => {
            if (!shareIdentifier) {
                return;
            }

            let index = photos.findIndex(
                (photo) => photo.shareIdentifier === shareIdentifier,
            );

            if (index >= 0 && photos[index + 1]) {
                setShareIdentifier(photos[index + 1].shareIdentifier);
            } else {
                setShareIdentifier(null);
            }
        },
        () => {
            if (!shareIdentifier) {
                return;
            }

            let index = photos.findIndex(
                (photo) => photo.shareIdentifier === shareIdentifier,
            );

            if (index >= 0 && photos[index - 1]) {
                setShareIdentifier(photos[index - 1].shareIdentifier);
            } else {
                setShareIdentifier(null);
            }
        },
    );

    return (
        <GalleryContext.Provider
            value={{ shareIdentifier, setShareIdentifier }}
        >
            <Loading isLoading={isLoading} />
            {!isLoading && (
                <div
                    className={`Gallery-header ${
                        shareIdentifier ? 'hidden invisible' : ''
                    }`}
                >
                    <div className="Gallery-header-title">
                        <img
                            src={gallery?.logo || logo}
                            alt="Smilebooth Logo"
                        />
                    </div>
                    <div className="Gallery-name">{gallery?.name}</div>
                </div>
            )}
            <div
                className={`Gallery-content ${
                    hasCTA ? 'pb-[100px]' : 'pb-[20px]'
                }`}
                id="galleryContainer"
            >
                <div
                    ref={containerRef}
                    className="relative w-full"
                    style={{ height: `${Math.max(...columnHeights)}px` }}
                >
                    {getPhotoPositions.map(
                        ({
                            id,
                            style,
                            imageStyle,
                            thumbnail,
                            full,
                            tiny,
                            shareIdentifier,
                        }) => (
                            // @ts-ignore
                            <div key={`${id}-gutter`} style={style ?? {}}>
                                <PhotoPreview
                                    thumbnail={thumbnail || full}
                                    tiny={tiny}
                                    style={imageStyle ?? {}}
                                    shareIdentifier={shareIdentifier}
                                />
                            </div>
                        ),
                    )}
                </div>
            </div>
            <GalleryLightbox
                photos={photos}
                currentPhotoIndex={photoIndex}
                gestureHandlers={swipeHandlers}
            />
        </GalleryContext.Provider>
    );
}

function PhotoPreview({
    style,
    thumbnail,
    tiny,
    shareIdentifier,
}: {
    style: any;
    thumbnail: string;
    tiny: string;
    shareIdentifier: string;
}) {
    const [uri, setUri] = useState<string>(tiny || thumbnail);
    const [, setIsLoading] = useState<boolean>(true);
    const { setShareIdentifier } = useContext(GalleryContext);

    useEffect(() => {
        const image = new Image();
        image.src = thumbnail;
        image.onload = () => {
            setUri(thumbnail);
            setIsLoading(false);
        };
    }, [thumbnail]);

    const handleClick = useCallback(() => {
        setShareIdentifier(shareIdentifier);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shareIdentifier]);

    return (
        <div
            key={shareIdentifier}
            style={{
                ...style,
                backgroundImage: `url(${uri})`,
                transition: 'all 0.3s linear',
            }}
            className="bg-no-repeat"
            id={`${shareIdentifier}-element`}
            onClick={handleClick}
        ></div>
    );
}
