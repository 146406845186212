import { useDispatch, useSelector, useStore } from 'react-redux';
import { addExperience, resetExperience } from '../utils/state/experienceSlice';
import {
    addScreen,
    resetScreens,
    updateScreen,
} from '../utils/state/screensSlice';
import {
    addProcessingFlow,
    resetProcessingFlows,
} from '../utils/state/processingFlowSlice';
import { useEffect } from 'react';

export default function useExperienceState(activationId: number) {
    const dispatch = useDispatch();
    const screens = useSelector((state: any) => {
        return state.screens.present.ids.map(
            (id: string) => state.screens.present.entities[id],
        );
    });

    const store = useStore();

    const loadExperience = (definition: Definition, activationId: number) => {
        dispatch(resetExperience());
        dispatch(resetScreens());
        dispatch(resetProcessingFlows());

        const flows = definition.processingFlows as Record<
            string,
            ProcessingFlow
        >;

        let experience = {
            id: activationId,
            screens: definition.screens.map((screen) => screen.id),
            processingFlows: Object.keys(flows),
            exitPin: definition.exitPin,
        };

        let screens = [...definition.screens];

        dispatch(addExperience(experience));

        screens.forEach((screen) => {
            dispatch(addScreen(screen));
        });

        Object.keys(flows).forEach((flowId: string) => {
            const flow = flows[flowId];
            dispatch(addProcessingFlow({ id: flowId, flow }));
        });
    };

    const handleChange = (
        screen: ScreenDefinition,
        name: string,
        value: string,
    ) => {
        let newData = { [name]: value };

        dispatch(updateScreen({ id: screen.id, data: newData }));
    };

    const handleSwitchChange = (
        screen: ScreenDefinition,
        name: string,
        value: boolean,
    ) => {
        let newData = { [name]: value };

        dispatch(updateScreen({ id: screen.id, data: newData }));
    };

    const handleSelectChange = (
        screen: ScreenDefinition,
        name: string,
        value: string,
    ) => {
        let newData = { [name]: value };

        dispatch(updateScreen({ id: screen.id, data: newData }));
    };

    const exportExperience = (activationId: number): Definition => {
        const storeState: any = store.getState();
        const localExperience =
            storeState.experiences.present.entities[activationId];
        const localScreens = storeState.screens.present.entities;

        const screenIds = storeState.screens.present.ids;
        const screens: ScreenDefinition[] = [];
        screenIds.forEach((screenId: ScreenId) => {
            screens.push(localScreens[screenId]);
        });

        const processingFlows = storeState.flows.present.entities;
        const flows: Record<string, ProcessingFlow> = {};
        Object.keys(processingFlows).forEach((flowId: string) => {
            flows[flowId] = processingFlows[flowId];
        });

        return {
            ...localExperience,
            screens,
            processingFlows: flows,
            exitPin: localExperience.exitPin,
        };
    };

    useEffect(() => {
        dispatch(resetExperience());
        dispatch(resetScreens());
        dispatch(resetProcessingFlows());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        loadExperience,
        screens,
        handleChange,
        handleSwitchChange,
        handleSelectChange,
        exportExperience,
    };
}
