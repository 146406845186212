import { useParams } from 'react-router-dom';
import './Gallery.css';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import GalleryShareContext from './GalleryShareContext';
import ShareModal from './ShareModal';
import useGalleryUser from '@/src/Share/components/hooks/useGalleryUser';
import CreateUserAccountModal from './CreateUserAccountModal';
import GlobalStyle from '@/src/Share/components/GalleryTheme';
import MasonryGallery from './MasonryGallery';
import useSingleDestinationGalleryInteractions from './hooks/useSingleDestinationGalleryInteractions';
import ExternalSurvey from '@/src/Share/components/ExternalSurvey';
import Loading from '@/src/Common/components/Loading';

export default function SingleDestinationActivationGallery() {
    const { destinationSlug, slug } = useParams();
    const {
        gallery,
        isLoading,
        photos,
        delayed,
        expandedIndex,
        setExpandedIndex,
        swipeHandlers,
        loadedPhotos,
        getNewPage,
    } = useSingleDestinationGalleryInteractions(
        String(slug),
        String(destinationSlug),
    );

    const [showShareModal, setShowShareModal] = useState(false);
    const [shareIdentifiers, setShareIdentifiers] = useState<string[]>([]);
    const [showJoinModal, setShowJoinModal] = useState(false);
    const [isSurveyLoaded, setIsSurveyLoaded] = useState(false);

    const triggerPrompt = useCallback(() => {
        setShowJoinModal(true);
    }, []);

    const { checkGalleryUser } = useGalleryUser(gallery, triggerPrompt);

    useUpdateEffect(() => {
        if (!gallery) {
            return;
        }

        if (!gallery?.displayGalleryUserSignup) {
            return;
        }

        let previousShareDestination = JSON.parse(
            // @ts-ignore
            localStorage.getItem(`sb-share-destination-${gallery?.id}`),
        );

        if (!previousShareDestination) {
            return;
        }

        checkGalleryUser(previousShareDestination);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gallery?.id, showShareModal]);

    const onShareButtonClick = useCallback((shareIdentifier: string) => {
        setShareIdentifiers([shareIdentifier]);
        setTimeout(() => {
            setShowShareModal(true);
        }, 50);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const context = useMemo(
        () => ({
            setShowShareModal: onShareButtonClick,
            setExpandedIndex,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    if (isLoading) {
        return <Loading isLoading={isLoading} />;
    }

    if (!gallery && !isLoading) {
        return (
            <div>
                <h3>gallery not found...</h3>
            </div>
        );
    }

    let shouldHideGallery = false;

    if (gallery?.surveyUrl && !isSurveyLoaded) {
        shouldHideGallery = true;
    }

    console.log('shouldHideGallery', shouldHideGallery);

    return (
        <GalleryShareContext.Provider value={context}>
            <div className="Gallery-view">
                <GlobalStyle
                    foregroundColor={gallery?.foregroundColor}
                    backgroundColor={gallery?.backgroundColor}
                    headerBackgroundColor={gallery?.headerBackgroundColor}
                    headerForegroundColor={gallery?.headerForegroundColor}
                />
                {!shouldHideGallery && (
                    <MasonryGallery
                        {...{
                            gallery,
                            isLoading,
                            photos,
                            delayed,
                            expandedIndex,
                            setExpandedIndex,
                            swipeHandlers,
                            loadedPhotos,
                            getNewPage,
                            galleryId: gallery?.id || 0,
                        }}
                    />
                )}
                {gallery && showJoinModal && (
                    <CreateUserAccountModal
                        galleryId={gallery?.id || 0}
                        onDismiss={() => {
                            setShowJoinModal(false);
                        }}
                    />
                )}
                {gallery && showShareModal && (
                    <ShareModal
                        {...{
                            gallery,
                            shareIdentifiers,
                            setShowShareModal,
                        }}
                    />
                )}
            </div>

            {gallery && gallery.surveyUrl ? (
                <ExternalSurvey
                    galleryInfo={gallery}
                    destinationIdentifier={destinationSlug || ''}
                    onLoad={() => {
                        setIsSurveyLoaded(true);
                    }}
                />
            ) : null}
        </GalleryShareContext.Provider>
    );
}

function useUpdateEffect(
    effect: React.EffectCallback,
    deps: React.DependencyList,
) {
    const isMounted = useRef(false);

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
        } else {
            return effect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
