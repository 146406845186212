import useAuthenticatedApi from '@/src/Authentication/components/hooks/useAuthenticatedApi';
import useUserOrg from '@/src/Authentication/components/hooks/useUserOrg';
import { toast } from 'sonner';

export default function useExperienceDefinition() {
    const authenticatedApi = useAuthenticatedApi();
    const { orgId } = useUserOrg();

    const getDefinition = async (activationId: number) => {
        const response = await authenticatedApi.get(
            `/${orgId}/experiences/${activationId}`,
        );

        return response.data;
    };

    const addDefinition = async (activationId: number, definition: string) => {
        const response = await authenticatedApi.post(
            `/${orgId}/experiences/${activationId}`,
            { definition },
        );

        toast.success('Experience saved');

        return response.data;
    };

    const saveDefinition = async (activationId: number, definition: string) => {
        const response = await authenticatedApi.put(
            `/${orgId}/experiences/${activationId}`,
            { definition },
        );

        toast.success('Experience saved');

        return response.data;
    };

    const deleteDefinition = async (activationId: number) => {
        const response = await authenticatedApi.delete(
            `/${orgId}/experiences/${activationId}`,
        );

        toast.success('Experience deleted');

        return response.data;
    };

    return {
        getDefinition,
        addDefinition,
        saveDefinition,
        deleteDefinition,
    };
}
