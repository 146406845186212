import { createContext } from 'react';

const GalleryContext = createContext<{
    shareIdentifier: string | null;
    setShareIdentifier: (shareIdentifier: string | null) => void;
}>({
    shareIdentifier: null,
    setShareIdentifier: () => {},
});

export default GalleryContext;
