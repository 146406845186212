import useAuthenticatedApi, {
    HTTP_STATUS_CODES,
} from '../../../Authentication/components/hooks/useAuthenticatedApi';
import api from '../../../services/api';

export default function useShare() {
    const authenticatedApi = useAuthenticatedApi();
    const createShare = async (valuesObject: any) => {
        const {
            activationId,
            galleryId,
            shareIdentifiers,
            destination,
            type,
            disclaimerAccepted,
        } = valuesObject;

        let personalGallerySlug = null;
        let id = null;

        try {
            const results = await api.post(`/share/create`, {
                destination,
                shareIdentifiers,
                type,
                activationId,
                galleryId,
                disclaimerAccepted,
            });

            if (results.status === HTTP_STATUS_CODES.OK) {
                personalGallerySlug = results.data[0].personal_gallery_slug;
                id = results.data[0].id;
            }
        } catch (e) {}

        return { personalGallerySlug, id };
    };

    const getSharesForActivation = async (activationId: number) => {
        const results = await authenticatedApi.get(
            `/shares/activation/${activationId}/media`,
        );

        return results.data.map((share: ShareObject) => {
            switch (share.type) {
                case 0:
                    share.type = 'email';
                    break;
                case 1:
                    share.type = 'text';
                    break;
                default:
                    break;
            }
            return share;
        });
    };

    const getShareStatsForActivation = async (activationId: number) => {
        const results = await authenticatedApi.get(
            `/activations/${activationId}/shareStats`,
        );

        return results.data;
    };

    const reprocessUnsentShares = async (activationId: number) => {
        const results = await authenticatedApi.post(
            `/shares/activation/${activationId}/reprocess`,
        );

        return results.data;
    };

    const addShareToSession = async (
        sessionId: string,
        shareIdentifier: string,
        destination: string,
        activationId: number,
        galleryId: number,
    ) => {
        let id = null;
        try {
            const results = await api.post(`/share/create`, {
                destination,
                shareIdentifiers: [shareIdentifier],
                photoSessionId: sessionId,
                type: destination.indexOf('@') > -1 ? 0 : 1,
                activationId,
                galleryId,
                preventSend: true,
            });
            if (results.status === HTTP_STATUS_CODES.OK) {
                id = results.data[0].id;
            }
        } catch (e) {}

        return { id };
    };

    const sendUnsentSharesForSession = async (sessionId: string) => {
        const results = await authenticatedApi.put(
            `/shares/session/${sessionId}/send`,
        );

        return results.data;
    };

    return {
        createShare,
        getSharesForActivation,
        reprocessUnsentShares,
        getShareStatsForActivation,
        sendUnsentSharesForSession,
        addShareToSession,
    };
}
