interface DialogFormRowProps {
    title: string;
    field: string;
    description?: string;
    children: React.ReactNode;
}

export default function DialogFormRow({
    title,
    field,
    description,
    children,
}: DialogFormRowProps) {
    return (
        <>
            <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                <div>
                    <label
                        htmlFor={field}
                        className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5 capitalize "
                    >
                        {title}
                    </label>
                </div>
                <div className="sm:col-span-2">{children}</div>
            </div>
            {description && (
                <p className="text-sm text-gray-500 px-6">{description}</p>
            )}
        </>
    );
}
